<template>
  <div class="ui-main-box">
    <div class="box-header">
      <strong>Select 下拉框</strong>
    </div>
    <div class="box-content">
      <div class="content-title">下拉框示例</div>
      <div class="content-demo">
        <div v-for="item in inputList" class="content-item">
          <div class="content-desc">{{ item.desc }}</div>
          <el-select 
            class="lc-select" 
            popper-class="lc-select-popper"
            v-model="item.input" 
            :collapse-tags="item.collapse" 
            :value="item.input" 
            :placeholder="item.desc" 
            :multiple="item.multiple" 
            :disabled="item.disabled">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="codeBlock">
        <el-button type="text" icon="el-icon-document-copy" @click="copyElement(setpData1)">复制代码</el-button>
        <div>
          <pre><code>{{ setpData1 }}</code></pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      setpData1: `    
        <div v-for="item in inputList" class="content-item">
          <div class="content-desc">{{ item.desc }}</div>
          <el-select 
            class="lc-select" 
            popper-class="lc-select-popper" 
            v-model="item.input" 
            :collapse-tags="item.collapse" 
            :value="item.input" 
            :placeholder="item.desc" 
            :multiple="item.multiple" 
            :disabled="item.disabled">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>

        export default {
          data() {
            return {
            
              inputList: [
                { desc: "普通下拉框", input: "", multiple: false, disabled: false, collapse: false },
                { desc: "禁止选择", input: "已禁用", multiple: false, disabled: true, collapse: false },
                { desc: "下拉多选框", input: "", multiple: true, disabled: false, collapse: false },
                { desc: "下拉多选框(折叠文字)", input: "", multiple: true, disabled: false, collapse: true },
                { desc: "禁止选择", input: "已禁用", multiple: true, disabled: true, collapse: true },
              ],

              options: [
                { value: '选项1', label: '黄金糕' },
                { value: '选项2', label: '双皮奶' },
                { value: '选项3', label: '蚵仔煎' },
                { value: '选项4', label: '龙须面' },
                { value: '选项5', label: '北京烤鸭' },
              ],
            };
          },
        }
      `,

      inputList: [
        { desc: "普通下拉框", input: "", multiple: false, disabled: false, collapse: false },
        { desc: "禁止选择", input: "已禁用", multiple: false, disabled: true, collapse: false },
        { desc: "下拉多选框", input: "", multiple: true, disabled: false, collapse: false },
        { desc: "下拉多选框(折叠文字)", input: "", multiple: true, disabled: false, collapse: true },
        { desc: "禁止选择", input: "已禁用", multiple: true, disabled: true, collapse: true },
      ],

      options: [
        { value: '选项1', label: '黄金糕' },
        { value: '选项2', label: '双皮奶' },
        { value: '选项3', label: '蚵仔煎' },
        { value: '选项4', label: '龙须面' },
        { value: '选项5', label: '北京烤鸭' },
      ],
    
    };
  },
  methods: {
    copyElement(val) {
      const nodeTextarea = document.createElement("textarea");
      // 隐藏该元素
      nodeTextarea.style.opacity = 0;
      // 写入元素内容（要复制的文本）
      nodeTextarea.value = val;
      // 将元素加入Dom中
      document.body.appendChild(nodeTextarea);
      // 选中该元素
      nodeTextarea.select();
      // 复制以选中元素的内容到粘贴板
      document.execCommand("Copy");
      // 复制完毕，移除该元素
      document.body.removeChild(nodeTextarea);
      this.$message.success("复制成功");
    },
  },
  created() { },
};
</script>

<style lang="less" scoped>
.box-content {
  .content-title {
    text-align: left;
    padding: 20px 0;
    font-size: 24px;
    color: #000;
  }

  .content-demo {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .content-item {
      width: 346px;
      margin-right: 100px;
      margin-bottom: 20px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }

    .content-desc {
      // width: 100px;
      text-align: left;
      margin-bottom: 10px;
    }
  }
}
</style>
