<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <div class="ui-title">
          <strong>HQ视觉组件规则</strong>
        </div>
      </el-header>
      <el-container>
        <el-aside width="240px">
          <LeftMenu @select="choiceHandle"></LeftMenu>
          <ColorVue v-if="currKey === 'colorVue'"></ColorVue>
          <ButtonVue v-if="currKey === 'buttonVue'"></ButtonVue>
          <!-- <CheckboxVue v-if="currKey === 'CheckboxVue'"></CheckboxVue> -->
          <MessageBoxVue v-if="currKey === 'messageBoxVue'"></MessageBoxVue>
          <TitleBarVue v-if="currKey === 'titleBarVue'"></TitleBarVue>
          <TagVue v-if="currKey === 'tagVue'"></TagVue>
          <StepVue v-if="currKey === 'stepVue'"></StepVue>
          <UploadVue v-if="currKey === 'uploadVue'"></UploadVue>
          <SelectVue v-if="currKey === 'selectVue'"></SelectVue>
          <CascaderVue v-if="currKey === 'cascaderVue'"></CascaderVue>
        </el-aside>
        <el-main>
          <el-scrollbar>
            <component :is="currKey"></component>
          </el-scrollbar>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import LeftMenu from './LeftMenu.vue';
import ColorVue from './color/color.vue';
import ButtonVue from './button/button.vue'
import PopoverVue from './popover/index.vue';
import MessageBoxVue from './MessageBox/index.vue';
import TitleBarVue from './TitleBar/index.vue';
import StepVue from './step/index.vue'
import TagVue from './tag/index.vue'
import UploadVue from './upload/index.vue'
import TableVue from './table/index.vue';
import PaginationVue from './pagination/index.vue';
import CollapseVue from './search-form-collapse/index.vue';
import CheckboxVue from './Checkbox/index.vue';
import RadioVue from './Radio/index.vue';
import InputVue from './input/index.vue';
import SelectVue from './select/index.vue';
import ListVue from './list/index.vue';
import FormVue from './form/index.vue';
import CascaderVue from './cascader/index.vue';
import DatePickerVue from './DatePicker/index.vue'
import SwitchVue from './switch/index.vue'
import ToolTipVue from './ToolTip/index';
import DialogVue from './Dialog/index';
import ViewsTableVue from './ViewTable/index';
import messageVue from './message/index';
export default {
  name: 'entry',
  components: {
    ColorVue,
    LeftMenu,
    ButtonVue,
    RadioVue,
    CheckboxVue,
    PopoverVue,
    MessageBoxVue,
    TitleBarVue,
    StepVue,
    UploadVue,
    TagVue,
    TableVue,
    PaginationVue,
    CollapseVue,
    InputVue,
    SelectVue,
    ListVue,
    FormVue,
    CascaderVue,
    DatePickerVue,
    SwitchVue,
    ListVue,
    ToolTipVue,
    DialogVue,
    ViewsTableVue,
    messageVue
  },
  data() {
    return {
      currKey: 'SelectVue'
    }
  },
  mounted() {
    let hashStr = window.location.hash || '';
    if(hashStr){
      this.currKey = hashStr.slice(1);
    }
  },
  methods: {
    choiceHandle(str){
      this.currKey = str;
      window.location.hash = `#${str}`;
    },
  }

}
</script>
<style lang="less" scoped>
  .el-header{
    background-color: var(--lc-color-text-7);
    text-align: center;
    .el-image{
      margin-top: 10px;
    }
    .ui-title{
      display: inline-block;
      vertical-align: top;
      font-size: 24px;
      color: white;
      margin-top: 14px;
      strong{
        font-weight: 400;
      }
    }
  }
  .el-main{
    box-shadow: -1px 0 0 0 #dcdfe6;
    position: relative;
    padding: 0;
    height: calc(100vh - 60px);
    user-select: auto;

  }
</style>
