<template>
  <div class="lc-title-bar-box">
    <div class="top_nav_link">
      <template v-if="list.length<=1">
        <div style="display: flex;align-items: center;">
          <div @click="goBack" v-if="showBack" class="back-icon"><i style="font-size: 20px;margin-top: 4px;" class="el-icon-arrow-left"></i></div>
          <span>{{list[0] && list[0].name}}</span>
        </div>
      </template>
      <template v-else>
        <el-menu :default-active="activeIndex" mode="horizontal"  @select="handleSelect">
          <el-menu-item :key="index" v-for="(item, index) in list" :index="index + ''">{{item.name}}</el-menu-item>
        </el-menu>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleBar',
  data() {
    return {
      activeIndex: '0'
    }
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    showBack: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    goBack() {
      if (this.$listeners.goBack) {
        this.$emit('goBack');
      } else {
        history.go(-1);
      }
    },
    handleSelect(index) {
      this.activeIndex = index;
      let item = this.list[index];
      if (this.$router && item && item.path) {
        this.$router.push(item.path);
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>