<template>
  <div class="ui-main-box">
    <div class="box-header">
      <strong>Tag 标签</strong>
    </div>
    <div class="box-main">
      <div class="box-content">
        <el-tag class="lc-tag" effect="plain">默认模板</el-tag>
        <div class="codeBlock">
          <el-button type="text" icon="el-icon-document-copy" @click="copyElement(tagData1)">复制代码</el-button>
          <div>
            <pre><code>{{ tagData1 }}</code></pre>
          </div>
        </div>
      </div>

      <div class="box-content">
        <el-tag class="lc-tag" effect="dark">OA审核中</el-tag>
        <div class="codeBlock">
          <el-button type="text" icon="el-icon-document-copy" @click="copyElement(tagData2)">复制代码</el-button>
          <div>
            <pre><code>{{ tagData2 }}</code></pre>
          </div>
        </div>
      </div>
    </div>

<!--    <div style="margin-top: 12px;">-->
<!--      <el-tag class="lc-tag" effect="dark">OA审核中</el-tag>-->
<!--    </div>-->

  </div>
</template>

<script>
export default {
  data() {
    return {
      tagData1: `
<el-tag class="lc-tag" effect="plain">默认模板</el-tag>
`,
      tagData2: `
<el-tag class="lc-tag" effect="dark">OA审核中</el-tag>
`
    }
  },
  created() {
  },
  methods: {
    copyElement(val) {
      const nodeTextarea = document.createElement('textarea');
      // 隐藏该元素
      nodeTextarea.style.opacity = 0;
      // 写入元素内容（要复制的文本）
      nodeTextarea.value = val;
      // 将元素加入Dom中
      document.body.appendChild(nodeTextarea);
      // 选中该元素
      nodeTextarea.select();
      // 复制以选中元素的内容到粘贴板
      document.execCommand('Copy');
      // 复制完毕，移除该元素
      document.body.removeChild(nodeTextarea);
      this.$message.success('复制成功');
    }
  }
}
</script>

<style lang="less" scoped>
</style>
