<template>
  <div class="ui-main-box" style="text-align: left;">
    <div class="box-header">
      <strong>Dialog 对话框</strong>
    </div>
    <div class="box-info-text">
      注意：增加 <p> custom-class="lc-dlg" </p>。以下代码可复制后直接使用。
    </div>
    <div class="box-content">
      <div class="secondTitle">1、弹窗-小号</div>
      <el-button @click="showSmallDlg = true">点击查看弹窗小号</el-button>
      <el-dialog
              append-to-body
              title="弹窗标题"
              :visible.sync="showSmallDlg"
              custom-class="lc-dlg small"
              width="440px">
        <div slot="footer">
          <el-button class="dialog-btn" type="primary">确定</el-button>
          <el-button class="dialog-btn cancel" type="primary" @click="showSmallDlg = false">取消</el-button>
        </div>
      </el-dialog>
      <div class="codeBlock">
        <el-button type="text" icon="el-icon-document-copy" @click="copyElement(elmentData)">复制代码</el-button>
        <div>
          <pre><code>{{elmentData}}</code></pre>
        </div>
      </div>
    </div>
    <div class="box-content">
      <div class="secondTitle">2、弹窗-中号</div>
      <el-button @click="showMiddleDlg = true">点击查看弹窗中号</el-button>
      <el-dialog
              append-to-body
              title="弹窗标题"
              :visible.sync="showMiddleDlg"
              custom-class="lc-dlg middle"
              width="800px">
        <div slot="footer">
          <el-button class="dialog-btn" type="primary">确定</el-button>
          <el-button class="dialog-btn cancel" type="primary" @click="showMiddleDlg = false">取消</el-button>
        </div>
      </el-dialog>
      <div class="codeBlock">
        <el-button type="text" icon="el-icon-document-copy" @click="copyElement(elmentData1)">复制代码</el-button>
        <div>
          <pre><code>{{elmentData1}}</code></pre>
        </div>
      </div>
    </div>
    <div class="box-content">
      <div class="secondTitle">2、弹窗-大号</div>
      <el-button @click="showLargeDlg = true">点击查看弹窗大号</el-button>
      <el-dialog
              append-to-body
              title="弹窗标题"
              :visible.sync="showLargeDlg"
              custom-class="lc-dlg large"
              width="1284px">
        <div slot="footer">
          <el-button class="dialog-btn" type="primary">确定</el-button>
          <el-button class="dialog-btn cancel" type="primary" @click="showLargeDlg = false">取消</el-button>
        </div>
      </el-dialog>
      <div class="codeBlock">
        <el-button type="text" icon="el-icon-document-copy" @click="copyElement(elmentData2)">复制代码</el-button>
        <div>
          <pre><code>{{elmentData2}}</code></pre>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      showSmallDlg: false,
      showMiddleDlg: false,
      showLargeDlg: false,
      elmentData: `
      <el-dialog
              append-to-body
              title="弹窗标题"
              :visible.sync="showSmallDlg"
              custom-class="lc-dlg small"
              width="440px">
        <div slot="footer">
          <el-button class="dialog-btn" type="primary">确定</el-button>
          <el-button class="dialog-btn cancel" type="primary">取消</el-button>
        </div>
      </el-dialog>
      `,
      elmentData1: `
      <el-dialog
              append-to-body
              title="弹窗标题"
              :visible.sync="showMiddleDlg"
              custom-class="lc-dlg middle"
              width="800px">
        <div slot="footer">
          <el-button class="dialog-btn" type="primary">确定</el-button>
          <el-button class="dialog-btn cancel" type="primary">取消</el-button>
        </div>
      </el-dialog>
      `,
      elmentData2: `
      <el-dialog
              append-to-body
              title="弹窗标题"
              :visible.sync="showLargeDlg"
              custom-class="lc-dlg large"
              width="1284px">
        <div slot="footer">
          <el-button class="dialog-btn" type="primary">确定</el-button>
          <el-button class="dialog-btn cancel" type="primary">取消</el-button>
        </div>
      </el-dialog>
      `
    }
  },
  methods: {
    copyElement(val) {
      const nodeTextarea = document.createElement('textarea');
      // 隐藏该元素
      nodeTextarea.style.opacity = 0;
      // 写入元素内容（要复制的文本）
      nodeTextarea.value = val;
      // 将元素加入Dom中
      document.body.appendChild(nodeTextarea);
      // 选中该元素
      nodeTextarea.select();
      // 复制以选中元素的内容到粘贴板
      document.execCommand('Copy');
      // 复制完毕，移除该元素
      document.body.removeChild(nodeTextarea);
      this.$message.success('复制成功');
    }

  }
}
</script>
<style lang="less" scoped>

</style>
