<template>
  <div class="ui-main-box">
    <div class="box-header">
      <strong>Form 表单</strong>
    </div>
    <div class="box-content">
      <el-form ref="form" class="lc-form-custom" :model="formData" :rules="rules" label-width="80px">
        <el-form-item label="参与酒店" prop="resource">
          <el-radio-group class="lc-radio-group" v-model="formData.resource">
            <el-radio label="全部酒店"></el-radio>
            <el-radio label="按品牌选择"></el-radio>
            <el-radio label="按酒店选择"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择品牌" prop="region">
          <el-select class="lc-select" popper-class="lc-select-popper" v-model="formData.region" placeholder="请选择">
            <el-option label="明宇集团" value="shanghai"></el-option>
            <el-option label="两江集团" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="黑名单" prop="name">
          <el-input class="lc-input" v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="白名单">
          <el-input class="lc-input" v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="对应题库">
          <el-select class="lc-select" popper-class="lc-select-popper" v-model="formData.region" placeholder="请选择">
            <el-option label="推广测试题集01" value="shanghai"></el-option>
            <el-option label="推广测试题集02" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下发时间" class="lc-item-container" prop="region">
          <el-row :gutter="8">
            <el-col :span="12">
              <el-select class="lc-select" popper-class="lc-select-popper" v-model="formData.region" placeholder="请选择">
                <el-option label="每月" value="shanghai"></el-option>
                <el-option label="每年" value="beijing"></el-option>
              </el-select>
            </el-col>
            <el-col :span="12">
              <el-input class="lc-input-unit" v-model="formData.name">
                <template slot="append">日</template>
              </el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="完成时间" class="lc-item-container">
          <el-row :gutter="8">
            <el-col :span="12">
              <el-input class="lc-input" v-model="formData.name"></el-input>
            </el-col>
            <el-col :span="12">
              <span>个工作日（下发后）</span>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="活动性质">
          <el-checkbox-group class="lc-checkbox-group" v-model="formData.type">
            <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
            <el-checkbox label="地推活动" name="type"></el-checkbox>
            <el-checkbox label="线下主题活动" name="type"></el-checkbox>
            <el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="活动描述">
          <el-input class="lc-input" type="textarea" v-model="formData.desc"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="lc-button" type="primary" @click="onSubmit">立即创建</el-button>
          <el-button class="lc-button" type="info">取消</el-button>
        </el-form-item>
      </el-form>
      <div class="codeBlock">
        <el-button
          type="text"
          icon="el-icon-document-copy"
          @click="copyElement(formCode1)"
          >复制代码</el-button
        >
        <div>
          <pre><code>{{ formCode1 }}</code></pre>
        </div>
      </div>
    </div>
  </div>  
</template>
<script>
  export default {
    data() {
      return {
        formData: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: '',
        },
        rules: {
          name: [
            { required: true, message: '请输入活动名称', trigger: 'blur' }
          ],
          region: [
            { required: true, message: '请选择活动区域', trigger: 'change' }
          ],
          date1: [
            { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
          ],
          date2: [
            { type: 'date', required: true, message: '请选择时间', trigger: 'change' }
          ],
          type: [
            { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
          ],
          resource: [
            { required: true, message: '请选择活动资源', trigger: 'change' }
          ],
          desc: [
            { required: true, message: '请填写活动形式', trigger: 'blur' }
          ]
        },
        formCode1: '',
      }
    },
    methods: {
      onSubmit() {
        console.log('submit!');
      },
      copyElement(text){
        const type = "text/plain";
        const blob = new Blob([text], { type });
        const data = [new ClipboardItem({ [type]: blob })];
        navigator.clipboard.write(data).then(() => {
          this.$message.success("复制成功");
        });
      },
    },
    mounted() {
      this.formCode1 = `
      <el-form ref="form" class="lc-form-custom" :model="formData" :rules="rules" label-width="80px">
        <el-form-item label="参与酒店" prop="resource">
          <el-radio-group class="lc-radio-group" v-model="formData.resource">
            <el-radio label="全部酒店"></el-radio>
            <el-radio label="按品牌选择"></el-radio>
            <el-radio label="按酒店选择"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择品牌" prop="region">
          <el-select class="lc-select" popper-class="lc-select-popper" v-model="formData.region" placeholder="请选择">
            <el-option label="明宇集团" value="shanghai"></el-option>
            <el-option label="两江集团" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="黑名单" prop="name">
          <el-input class="lc-input" v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="白名单">
          <el-input class="lc-input" v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="对应题库">
          <el-select class="lc-select" popper-class="lc-select-popper" v-model="formData.region" placeholder="请选择">
            <el-option label="推广测试题集01" value="shanghai"></el-option>
            <el-option label="推广测试题集02" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下发时间" class="lc-item-container" prop="region">
          <el-row :gutter="8">
            <el-col :span="12">
              <el-select class="lc-select" popper-class="lc-select-popper" v-model="formData.region" placeholder="请选择">
                <el-option label="每月" value="shanghai"></el-option>
                <el-option label="每年" value="beijing"></el-option>
              </el-select>
            </el-col>
            <el-col :span="12">
              <el-input class="lc-input-unit" v-model="formData.name">
                <template slot="append">日</template>
              </el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="完成时间" class="lc-item-container">
          <el-row :gutter="8">
            <el-col :span="12">
              <el-input class="lc-input" v-model="formData.name"></el-input>
            </el-col>
            <el-col :span="12">
              <span>个工作日（下发后）</span>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="活动性质">
          <el-checkbox-group class="lc-checkbox-group" v-model="formData.type">
            <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
            <el-checkbox label="地推活动" name="type"></el-checkbox>
            <el-checkbox label="线下主题活动" name="type"></el-checkbox>
            <el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="活动描述">
          <el-input class="lc-input" type="textarea" v-model="formData.desc"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="lc-button" type="primary" @click="onSubmit">立即创建</el-button>
          <el-button class="lc-button" type="info">取消</el-button>
        </el-form-item>
      </el-form>`;
    }
  }

</script>
<style lang="less" scoped>
.box-content{
  .el-form{
    width: 460px;
    text-align: left;
  }
}
</style>
