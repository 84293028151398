<template>
  <div class="ui-menu-box hq-vue2-comm">
    <el-scrollbar>
      <el-menu
        default-active="1-1"
        class="ui-left-menu"
        :default-openeds="openkeys"
        @select="selectHandle"
      >
        <template v-for="item in menuObj">
          <el-submenu :index="item.index" v-if="item.children">
            <template slot="title">{{item.name}}</template>
            <el-menu-item :index="child.key" v-for="child in item.children">{{child.name}}</el-menu-item>
          </el-submenu>
          <el-menu-item :index="item.index" v-else>{{item.name}}</el-menu-item>
        </template>
      </el-menu>
    </el-scrollbar>
  </div>
</template>
<script>
export default {
  name: 'leftMenu',
  data() {
    return {
      menuObj: [
        {
          index: '1',
          name: 'Basic 基础组件',
          children: [
            {
              key: 'ColorVue',
              name: 'Color 颜色'
            },
            // {
            //   key: 'GridVue',
            //   name: 'Grid 布局'
            // },
            // {
            //   key: 'IntervalVue',
            //   name: 'Interval 间距'
            // },
            // {
            //   key: 'TypographyVue',
            //   name: 'Typography 文字排版'
            // },
            // {
            //   key: 'IconsVue',
            //   name: 'Icons 图标'
            // },
            {
              key: 'ButtonVue',
              name: 'Button 按钮'
            },
            {
              key: 'RadioVue',
              name: 'Radio 单选框'
            },
            {
              key: 'CheckboxVue',
              name: 'Checkbox 多选框'
            },
            {
              key: 'SelectVue',
              name: 'Select 下拉框'
            },
            {
              key: 'CascaderVue',
              name: 'Cascader 级联下拉框'
            },
            {
              key: 'DatePickerVue',
              name: 'DatePicker 日期选择器'
            },
            {
              key: 'InputVue',
              name: 'Input 输入框'
            },
            {
              key: 'FormVue',
              name: 'Form 表单'
            },
            {
              key: 'SwitchVue',
              name: 'Switch 开关'
            },
            {
              key: 'PopoverVue',
              name: 'Popover 弹出框'
            },
            {
              key: 'MessageBoxVue',
              name: 'MessageBox 二次确认弹框'
            },
            {
              key: 'TitleBarVue',
              name: 'TitleBar 标题栏'
            },
            {
              key: 'TagVue',
              name: 'Tag 标签'
            },
            {
              key: 'StepVue',
              name: 'Step 步骤条'
            },
            {
              key: 'UploadVue',
              name: 'Upload 上传'
            },
            {
              key: 'TableVue',
              name: 'Table 表格'
            },
            {
              key: 'PaginationVue',
              name: 'Pagination 分页'
            },
            {
              key: 'CollapseVue',
              name: 'Collapse 搜索条件的展开收起'
            },
            {
              key: 'ListVue',
              name: 'List 列表页面布局'
            },
            {
              key: 'ToolTipVue',
              name: 'ToolTip 文字提示'
            },
            {
              key: 'DialogVue',
              name: 'Dialog 弹窗'
            },
            {
              key: 'ViewsTableVue',
              name: 'ViewsTable 查看态表格'
            },
            {
              key: 'messageVue',
              name: 'messageVue 消息提示'
            }
          ]
        }
      ],
      openkeys: ['1']
    }
  },
  mounted() {

  },
  methods: {
    selectHandle(key, path){
      this.$emit('select', key);
    }
  }
}
</script>
<style lang="less" scoped>
  .ui-menu-box.hq-vue2-comm{
    height: calc(100vh - 60px);
    /deep/ .el-menu {
      border-right: 0;
      text-align: left;
      strong{
        font-size: calc(var(--el-font-size-base) + 2px);
      }
      .el-menu-item:hover{
        background-color: var(--lc-color-text-2);
      }
      .el-menu-item.is-active{
        color: #fff;
        background-color: var(--lc-color-text-7) !important;
      }
    }
  }

</style>
