<template>
  <div class="ui-main-box" style="text-align: left;">
    <div class="box-header">
      <strong>ViewTable 查看态表格</strong>
    </div>
    <div class="box-info-text">
      以下代码可复制后直接使用。
      注意：
      查看态表格-横列 要让最后一个元素填满剩余空间 因此需要增加以下代码
      :class="{'fill-up': index + 1 === dataList.length && dataList.length%4 !== 0}"
    </div>
    <div class="box-content">
      <div class="secondTitle">1、查看态表格-横列</div>
      <div class="lc-views-table views-horizontal">
        <div v-for="(item, index) in dataList"
             class="list" :class="{'fill-up': index + 1 === dataList.length && dataList.length%4 !== 0}">
          <div class="label">{{item.label}}</div>
          <div class="info">{{item.info}}</div>
        </div>
      </div>
      <div class="codeBlock">
        <el-button type="text" icon="el-icon-document-copy" @click="copyElement(elmentData)">复制代码</el-button>
        <div>
          <pre><code>{{elmentData}}</code></pre>
        </div>
      </div>
    </div>
    <div class="box-content">
      <div class="secondTitle">2、查看态表哥-纵列</div>
      <div class="lc-views-table views-vertical">
        <div class="list">
          <div class="label">标题1</div>
          <div class="info">内容1</div>
        </div>
        <div class="list">
          <div class="label">标题2</div>
          <div class="info">内容2</div>
        </div>
      </div>
      <div class="codeBlock">
        <el-button type="text" icon="el-icon-document-copy" @click="copyElement(elmentData1)">复制代码</el-button>
        <div>
          <pre><code>{{elmentData1}}</code></pre>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataList: [
        {label: '标题', info: '内容'},
        {label: '标题', info: '内容'},
        {label: '标题', info: '内容'},
        {label: '标题', info: '内容'},
        {label: '标题', info: '内容'},
        {label: '标题', info: '内容'},
      ],
      elmentData: `
      <div class="lc-views-table views-horizontal">
        <div v-for="(item, index) in dataList"
             class="list" :class="{'fill-up': index + 1 === dataList.length && dataList.length%4 !== 0}">
          <div class="label">{{item.label}}</div>
          <div class="info">{{item.info}}</div>
        </div>
      </div>
      `,
      elmentData1: `
      <div class="lc-views-table views-vertical">
        <div class="list" v-for="(item, index) in dataList">
          <div class="label">{{item.label}}</div>
          <div class="info">{{item.info}}</div>
        </div>
      </div>
      `,
    }
  },
  methods: {
    copyElement(val) {
      const nodeTextarea = document.createElement('textarea');
      // 隐藏该元素
      nodeTextarea.style.opacity = 0;
      // 写入元素内容（要复制的文本）
      nodeTextarea.value = val;
      // 将元素加入Dom中
      document.body.appendChild(nodeTextarea);
      // 选中该元素
      nodeTextarea.select();
      // 复制以选中元素的内容到粘贴板
      document.execCommand('Copy');
      // 复制完毕，移除该元素
      document.body.removeChild(nodeTextarea);
      this.$message.success('复制成功');
    }

  }
}
</script>
<style lang="less" scoped>

</style>
