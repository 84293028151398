<template>
  <div class="ui-main-box">
    <div class="box-header">
      <strong>01. 界面UI颜色</strong>
    </div>
    <div class="color-box box-content" v-for="item in colorItems">
      <div class="box-left">
        <div class="left-title">{{item.name}}</div>
        <div class="left-desc">{{item.desc}}</div>
      </div>
      <div class="box-right">
        <ul>
          <li v-for="child in item.children">
            <div class="item-color-text" :style="child.invert ? `color: var(${item.base})` : 'color: #fff'">{{child.cvalue}}</div>
            <div class="item-color" :style="`background-color: var(${child.value});`"></div>
            <div class="item-name">{{child.name}}</div>
            <div class="item-detail">{{child.scene}}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      colorItems: [
        {
          "index":1,"name":"Brand Color","desc":"主色序列 Brand","base":"--lc-color-brand-7",
          "children":[
            {"name":"Brand 9","scene":"高亮/链接色","value":"--lc-color-brand-9","invert":false,"cvalue":"#FF9505"},
            {"name":"Brand 8","scene":"高亮/链接色","value":"--lc-color-brand-8","invert":false,"cvalue":"#FFAD3D"},
            {"name":"Brand 7","scene":"点击","value":"--lc-color-brand-7","invert":false,"cvalue":"#0F1787"},
            {"name":"Brand 6","scene":"常规","value":"--lc-color-brand-6","invert":false,"cvalue":"#2C34AA"},
            {"name":"Brand 5","scene":"悬浮","value":"--lc-color-brand-5","invert":false,"cvalue":"#5158B8"},
            {"name":"Brand 4","scene":"特殊场景","value":"--lc-color-brand-4","invert":false,"cvalue":"#9BAAF4"},
            {"name":"Brand 3","scene":"一般禁用","value":"--lc-color-brand-3","invert":false,"cvalue":"#B3B6E1"},
            {"name":"Brand 2","scene":"浅色/白底悬浮","value":"--lc-color-brand-2","invert":true,"cvalue":"#ECEFFF"},
            {"name":"Brand 1","scene":"更浅色模块","value":"--lc-color-brand-1","invert":true,"cvalue":"#F7F8FF"},
          ]
        },
        {
          "index":2,"name":"Neutral Color","desc":"边框 Border","base":"--lc-color-text-7",
          "children":[
            {"name":"color-border 6","scene":"重/按钮描边","value":"--lc-color-border-6","invert":false,"cvalue":"#777C85"},
            {"name":"color-border 5","scene":"深/悬浮","value":"--lc-color-border-5","invert":true,"cvalue":"#AAAAAA"},
            {"name":"color-border 4","scene":"深表格线","value":"--lc-color-border-4","invert":true,"cvalue":"#CCCCCC"},
            {"name":"color-border 3","scene":"一般","value":"--lc-color-border-3","invert":true,"cvalue":"#DDDDDD"},
            {"name":"color-border 2","scene":"浅表格线","value":"--lc-color-border-2","invert":true,"cvalue":"#E3E3E3"},
            {"name":"color-border 1","scene":"更浅","value":"--lc-color-border-1","invert":true,"cvalue":"#EEEEEE"}
          ]
        },
        {
          "index":3,"name":"","desc":"填充 Fill","base":"--lc-color-text-7",
          "children":[
            {"name":"color-fill 7","scene":"强调/图标","value":"--lc-color-fill-7","invert":false,"cvalue":"#071022"},
            {"name":"color-fill 6","scene":"重/特殊场景","value":"--lc-color-fill-6","invert":false,"cvalue":"#CCCCCC"},
            {"name":"color-fill 5","scene":"深/灰底悬浮","value":"--lc-color-fill-5","invert":true,"cvalue":"#E8E8E8"},
            {"name":"color-fill 4","scene":"一般/浅色底悬浮","value":"--lc-color-fill-4","invert":true,"cvalue":"#F4F4F4"},
            {"name":"color-fill 3","scene":"次级内容背景","value":"--lc-color-fill-3","invert":true,"cvalue":"#F8F8F8"},
            {"name":"color-fill 2","scene":"一般内容背景","value":"--lc-color-fill-2","invert":true,"cvalue":"#FAFAFA"},
            {"name":"color-fill 1","scene":"白底悬浮/禁用","value":"--lc-color-fill-1","invert":true,"cvalue":"#FAFBFF"}
          ]
        },
        {
          "index":4,"name":"","desc":"文本 Text","base":"--lc-color-text-7",
          "children":[
            {"name":"color-text 7","scene":"强调/高级别标题","value":"--lc-color-text-7","invert":false,"cvalue":"#071022"},
            {"name":"color-text 6","scene":"次强调/标题","value":"--lc-color-text-6","invert":false,"cvalue":"#5C5F66"},
            {"name":"color-text 5","scene":"次级正文","value":"--lc-color-text-5","invert":false,"cvalue":"#6B6F77"},
            {"name":"color-text 4","scene":"次级信息","value":"--lc-color-text-4","invert":false,"cvalue":"#777C85"},
            {"name":"color-text 3","scene":"暗提示/次次级信息","value":"--lc-color-text-3","invert":true,"cvalue":"#999FAA"},
            {"name":"color-text 2","scene":"置灰信息","value":"--lc-color-text-2","invert":true,"cvalue":"#CCCCCC"},
            {"name":"color-text 1","scene":"纯白文字","value":"--lc-color-text-1","invert":true,"cvalue":"#FFFFFF"}
          ]
        },
        {
          "index":5,"name":"Function Color","desc":"成功 Success","base":"--lc-color-success-6",
          "children":[
            {"name":"success 6","scene":"点击","value":"--lc-color-success-6","invert":false,"cvalue":"#008A4A"},
            {"name":"success 5","scene":"常规","value":"--lc-color-success-5","invert":false,"cvalue":"#00AB5B"},
            {"name":"success 4","scene":"悬浮","value":"--lc-color-success-4","invert":false,"cvalue":"#00BF66"},
            {"name":"success 3","scene":"禁用","value":"--lc-color-success-3","invert":false,"cvalue":"#8DE0B8"},
            {"name":"success 2","scene":"特殊场景","value":"--lc-color-success-2","invert":true,"cvalue":"#D2F4E4"},
            {"name":"success 1","scene":"浅色背景","value":"--lc-color-success-1","invert":true,"cvalue":"#E8FFF5"}
          ]
        },
        {
          "index":6,"name":"","desc":"提示 Warning","base":"--lc-color-warning-6",
          "children":[
            {"name":"warning 6","scene":"点击","value":"--lc-color-warning-6","invert":false,"cvalue":"#D68100"},
            {"name":"warning 5","scene":"常规","value":"--lc-color-warning-5","invert":false,"cvalue":"#F5970A"},
            {"name":"warning 4","scene":"悬浮","value":"--lc-color-warning-4","invert":false,"cvalue":"#FFAD33"},
            {"name":"warning 3","scene":"禁用","value":"--lc-color-warning-3","invert":false,"cvalue":"#FFC369"},
            {"name":"warning 2","scene":"特殊场景","value":"--lc-color-warning-2","invert":true,"cvalue":"#FFE3BA"},
            {"name":"warning 1","scene":"浅色背景","value":"--lc-color-warning-1","invert":true,"cvalue":"#FFF3E2"}
          ]
        },
        {
          "index":7,"name":"","desc":"错误 Danger","base":"--lc-color-danger-6",
          "children":[
            {"name":"danger 6","scene":"点击","value":"--lc-color-danger-6","invert":false,"cvalue":"#BF0D00"},
            {"name":"danger 5","scene":"常规","value":"--lc-color-danger-5","invert":false,"cvalue":"#DA1103"},
            {"name":"danger 4","scene":"悬浮","value":"--lc-color-danger-4","invert":false,"cvalue":"#FF3B30"},
            {"name":"danger 3","scene":"禁用","value":"--lc-color-danger-3","invert":false,"cvalue":"#FE8780"},
            {"name":"danger 2","scene":"特殊场景","value":"--lc-color-danger-2","invert":true,"cvalue":"#FFD6D4"},
            {"name":"danger 1","scene":"浅色背景","value":"--lc-color-danger-1","invert":true,"cvalue":"#FFF6F6"}
          ]
        }
      ],
    }
  }
}
</script>
<style lang="less" scoped>

  .color-box{
    white-space: nowrap;
    text-align: left;
    & + .color-box{
      margin-top: 120px;
    }
    .box-left, .box-right{
      display: inline-block;
      vertical-align: top;
    }
    .box-left{
      width: 240px;
      padding-top: 1px;
      .left-title{
        font-size: 20px;
        font-weight: 600;
        & + .left-desc{
          margin-top: 16px;
        }
      }
      .left-desc{
        font-size: 14px;
      }
    }
    .box-right{
      ul li{
        display: inline-block;
        vertical-align: top;
        position: relative;
      }
      .item-color-text{
        position: absolute;
        top: 24px;
        left: 26px;
      }
      .item-color{
        width: 126px;
        height: 66px;
      }
      .item-name{
        font-size: 14px;
        margin-top: 16px;
      }
      .item-detail{
        margin-top: 12px;
        font-size: 12px;
      }
    }
  }

</style>

