<template>
  <div class="ui-main-box">
    <div class="box-header">
      <strong>Input 输入框</strong>
    </div>
    <div class="box-content">
      <div class="content-title">1、普通输入框</div>
      <div class="content-demo">
        <div v-for="item in inputList" class="content-item">
          <div class="content-desc">{{ item.desc }}</div>
          <el-input
            class="lc-input"
            v-model="item.input"
            placeholder="Please input"
            :disabled="item.disabled"
          />
        </div>
      </div>
      <div class="codeBlock">
        <el-button
          type="text"
          icon="el-icon-document-copy"
          @click="copyElement(setpData1)"
          >复制代码</el-button
        >
        <div>
          <pre><code>{{ setpData1 }}</code></pre>
        </div>
      </div>
    </div>
    <div class="box-content">
      <div class="content-title">2、带单位输入框</div>
      <div class="content-demo">
        <div v-for="item in inputList2" class="content-item short">
          <div class="content-desc">{{ item.desc }}</div>
          <el-input
            class="lc-input-unit"
            placeholder="请输入内容"
            v-model="item.input"
            :disabled="item.disabled"
          >
            <template slot="append">元</template>
          </el-input>
        </div>
      </div>
      <div class="codeBlock">
        <el-button
          type="text"
          icon="el-icon-document-copy"
          @click="copyElement(setpData2)"
          >复制代码</el-button
        >
        <div>
          <pre><code>{{ setpData2 }}</code></pre>
        </div>
      </div>
    </div>
    <div class="box-content">
      <div class="content-title">3、文本域</div>
      <div class="content-demo">
        <div class="content-item">
          <el-input
            class="lc-input-textarea"
            type="textarea"
            :autosize="{ minRows: 1, maxRows: 4 }"
            placeholder="请输入内容"
            v-model="textarea"
          >
          </el-input>
        </div>
      </div>
      <div class="codeBlock">
        <el-button
          type="text"
          icon="el-icon-document-copy"
          @click="copyElement(setpData3)"
          >复制代码</el-button
        >
        <div>
          <pre><code>{{ setpData3 }}</code></pre>
        </div>
      </div>
    </div>
    <div class="box-content">
      <div class="content-title">4、带输入建议</div>
      <div class="content-demo">
        <div class="content-item">
          <el-autocomplete
            class="lc-input-autocomplete"
            v-model="state1"
            :fetch-suggestions="querySearch"
            placeholder="请输入内容"
            @select="handleSelect"
          ></el-autocomplete>
        </div>
      </div>
      <div class="codeBlock">
        <el-button
          type="text"
          icon="el-icon-document-copy"
          @click="copyElement(setpData4)"
          >复制代码</el-button
        >
        <div>
          <pre><code>{{ setpData4 }}</code></pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      restaurants: [],
      state1: "",
      setpData1: `    
      <el-input class="lc-input" v-model="item.input" placeholder="Please input" :disabled="item.disabled" />
      `,
      setpData2: `    
      <el-input class="lc-input-unit" placeholder="请输入内容" v-model="item.nput" :disabled="item.disabled">
        <template slot="append">元</template>
      </el-input>
      `,
      setpData3: `    
      <el-input class="lc-input-textarea" type="textarea" :autosize="{ minRows: 1, maxRows: 4 }" placeholder="请输入内容" v-model="textarea">
      </el-input>
      `,
      setpData4: `
       <el-autocomplete class="lc-input-autocomplete" v-model="state1" :fetch-suggestions="querySearch" placeholder="请输入内容" @select="handleSelect">
      </el-autocomplete>`,
      textarea: "",
      inputList: [
        { desc: "未填写", input: "", disabled: false },
        { desc: "鼠标悬浮", input: "", disabled: false },
        { desc: "点击填写中", input: "writing", disabled: false },
        { desc: "已填写完", input: "over", disabled: false },
        { desc: "禁止填写", input: "not", disabled: true },
      ],
      inputList2: [
        { desc: "未填写", input: "", disabled: false },
        { desc: "鼠标悬浮", input: "", disabled: false },
        { desc: "点击填写中", input: "writing", disabled: false },
        { desc: "禁止填写", input: "not", disabled: true },
      ],
    };
  },
  created() {},
  methods: {
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelect(item) {
      console.log(item);
    },

    copyElement(val) {
      const nodeTextarea = document.createElement("textarea");
      // 隐藏该元素
      nodeTextarea.style.opacity = 0;
      // 写入元素内容（要复制的文本）
      nodeTextarea.value = val;
      // 将元素加入Dom中
      document.body.appendChild(nodeTextarea);
      // 选中该元素
      nodeTextarea.select();
      // 复制以选中元素的内容到粘贴板
      document.execCommand("Copy");
      // 复制完毕，移除该元素
      document.body.removeChild(nodeTextarea);
      this.$message.success("复制成功");
    },
  },
  mounted() {
    this.restaurants = [
      { value: "三全鲜食（北新泾店）", address: "长宁区新渔路144号" },
      {
        value: "Hot honey 首尔炸鸡（仙霞路）",
        address: "上海市长宁区淞虹路661号",
      },
      {
        value: "新旺角茶餐厅",
        address: "上海市普陀区真北路988号创邑金沙谷6号楼113",
      },
      { value: "泷千家(天山西路店)", address: "天山西路438号" },
      {
        value: "胖仙女纸杯蛋糕（上海凌空店）",
        address: "上海市长宁区金钟路968号1幢18号楼一层商铺18-101",
      },
    ];
  },
};
</script>

<style lang="less" scoped>
.box-content {
  .content-title {
    text-align: left;
    padding: 20px 0;
    font-size: 24px;
    color: #000;
  }
  .content-demo {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .content-item {
      width: 346px;
      margin-right: 100px;
      margin-bottom: 20px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      &.short {
        width: 209px;
      }
    }
    .content-desc {
      // width: 100px;
      text-align: left;
      margin-bottom: 10px;
    }
  }
}
</style>
