<template>
  <div class="ui-main-box">
    <div class="box-header">
      <strong>Collapse 搜索条件的展开收起</strong>
    </div>
    <div class="box-main">
      <div class="box-content">
        <div class="box-info-text">
          1、下方代码为完整的搜索查询条件、查询重置和展开收起按钮，需要在data中定义一个变量showAllConditons，为需要在收起时隐藏的元素在filter-margin元素上设置v-show="showAllConditons"
        </div>
        <div class="box-info-text">
          2、需要添加 <p> "lc-search-form-collapse" </p>，设置表单元素和展开收起按钮样式
        </div>
        <div class="box-info-text">
          3、展开收起按钮代码在el-link标签中        
        </div>
        <div class="box-info-text">
          4、对于input和select标签，可能存在前缀文字的情况， 为实现统一控制输入框或选择框的左内边距,
          可以在el-select的class上添加two或three或four或five或six-word-prefix-input的类名
        </div>
        <div class="box-info-text">
          5、移除el-select的箭头图标，可以在el-select的class上添加remove-arrow的类名
        </div>
        <div class="text-show">
          <el-card shadow="never">
            <div class="conditionContainer lc-search-form-collapse">
              <div class="filter-margin">
                <el-select
                  popper-class="lc-select-popper"
                  :class="`lc-select prefix-icon-select remove-arrow one-word-prefix-input ${querySearchData.orderNumber ? 'hasData' : ''}`"
                  filterable
                  clearable
                  v-model="querySearchData.orderNumber"
                  suffix-icon=""
                  placeholder="查询订单号"
                >
                  <template slot="prefix">
                    <i class="el-icon-search"></i>
                  </template>
                  <el-option
                    v-for="item in orderNumberList"
                    :key="item.val"
                    :label="item.name"
                    :value="item.val"
                  />
                </el-select>
              </div>
              <div class="filter-margin">
                <el-select
                  popper-class="lc-select-popper"
                  :class="`lc-select prefix-text-select four-word-prefix-input ${querySearchData.orderStatus ? 'hasData' : ''}`"
                  filterable 
                  clearable
                  v-model="querySearchData.orderStatus"
                  placeholder="请选择"
                >
                  <template slot="prefix">订单状态</template>
                  <el-option
                    v-for="item in orderStatusList"
                    :key="item.val"
                    :label="item.name"
                    :value="item.val"
                  />
                </el-select>
              </div>
              <div class="filter-margin">
                <el-select
                  popper-class="lc-select-popper"
                  class="lc-select has-right filterable"
                  filterable
                  clearable
                  v-model="querySearchData.dateType"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in dateTypeList"
                    :key="item.val"
                    :label="item.name"
                    :value="item.val"
                  />
                </el-select>
                <el-date-picker
                  class="lc-date-picker has-left"
                  popper-class="lc-date-picker" 
                  :style="{ width: '262px' }"
                  @change="chooseDate"
                  v-model="querySearchData.dateList"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="入住日期"
                  end-placeholder="离店日期"
                />
              </div>
              <div class="filter-margin">
                <el-select 
                  class="lc-select prefixText muiltSelect"             
                  popper-class="lc-select-popper" 
                  v-model="querySearchData.hotelIdList" 
                  :class="{'hasData': querySearchData.hotelIdList.length > 0}" 
                  multiple 
                  collapse-tags 
                  filterable 
                  clearable 
                  placeholder="全部"
                >
                  <template slot="prefix">酒店名称/UPID</template>
                  <el-option v-for="item in selHotelList" :key="item.id" :value="item.id" :label="item.upid + ' ' + item.name" />
                </el-select>
              </div>
              <div class="filter-margin" v-show="showAllConditons">
                <el-input 
                  :class="`lc-input prefix-input four-word-prefix-input ${querySearchData.name ? 'hasData' : ''}`"
                  v-model="querySearchData.name" 
                  placeholder="请输入"
                >
                  <template slot="prefix">客人姓名</template>
                </el-input>
              </div>
              <div class="filter-margin" v-show="showAllConditons">
                <el-input 
                  :class="`lc-input prefix-input four-word-prefix-input ${querySearchData.phone ? 'hasData' : ''}`"
                  v-model="querySearchData.phone" 
                  placeholder="请输入"
                >
                  <template slot="prefix">客人电话</template>
                </el-input>
              </div>
              <div class="filter-margin" v-show="showAllConditons">
                <el-select
                  popper-class="lc-select-popper"
                  :class="`lc-select prefix-text-select four-word-prefix-input ${querySearchData.payType ? 'hasData' : ''}`"
                  filterable 
                  clearable
                  v-model="querySearchData.payType"
                  placeholder="请选择"
                >
                  <template slot="prefix">支付方式</template>
                  <el-option
                    v-for="item in payTypeList"
                    :key="item.val"
                    :label="item.name"
                    :value="item.val"
                  />
                </el-select>
              </div>
              <div class="filter-margin" v-show="showAllConditons">
                <el-select
                  popper-class="lc-select-popper"
                  :class="`lc-select prefix-text-select two-word-prefix-input ${querySearchData.channel ? 'hasData' : ''}`"
                  filterable 
                  clearable
                  v-model="querySearchData.channel"
                  placeholder="请选择"
                >
                  <template slot="prefix">渠道</template>
                  <el-option
                    v-for="item in channelList"
                    :key="item.val"
                    :label="item.name"
                    :value="item.val"
                  />
                </el-select>
              </div>
              <div
                class="search-btn-container"
                :style="showAllConditons ? 'padding-top:0;margin-bottom:12px' : 'margin-bottom:12px'"
              >
                <el-button
                  class="lc-button"
                  type="primary"
                  @click="queryTableList"
                  size="mini"
                  v-preventReClick="2000"
                >
                  查询
                </el-button>
                <el-button
                  class="lc-button"
                  @click="resetSearchType"
                  size="mini"
                  type="info"
                >
                  重置
                </el-button>
                <el-link
                  :underline="false"
                  @click="showAllConditons = !showAllConditons"
                  style="margin: 0 8px 0 8px;position:relative"
                >
                  <template v-if="showAllConditons === true">
                    <span class="collapse-btn">收起</span>
                    <i class="el-icon-arrow-up"></i>
                  </template>
                  <template v-else>
                    <span class="expand-btn">展开</span>
                    <i class="el-icon-arrow-down"></i>
                  </template>
                </el-link>
              </div>
            </div>
          </el-card>
        </div>
        <div class="codeBlock">
          <el-button type="text" icon="el-icon-document-copy" @click="copyElement(searchFormCollapleCode)">复制代码</el-button>
          <div>
            <pre><code>{{ searchFormCollapleCode }}</code></pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { copyText } from '../../utils/index'
export default {
  name: "CollapseVue",
  data() {
    return {
      showAllConditons: false,
      hotelIdList: [
        {val: 1, name: "UPID"},
        {val: 2, name: "携程ID"},
      ],
      selHotelList: [
        {
          "id": 210651,
          "name": "中濠嘉际酒店（东莞松山湖北站店）",
          "upid": 210651
        },
        {
          "id": 222115,
          "name": "中濠悦际酒店（深圳国际会展中心店）",
          "upid": 222115
        },
        {
          "id": 1000000876,
          "name": "中濠国际酒店（重庆华南城店）",
          "upid": 26261
        },
        {
          "id": 1000000878,
          "name": "中濠悦际酒店（深圳前海店）",
          "upid": 24616
        },
        {
          "id": 1000000879,
          "name": "中濠国际酒店（东莞长安万达广场店）",
          "upid": 24619
        },
        {
          "id": 1000000880,
          "name": "中濠国际酒店（深圳宝安石岩店）",
          "upid": 24618
        },
        {
          "id": 1000000881,
          "name": "中濠悦际酒店（深圳龙华大浪商业中心店）",
          "upid": 24621
        },
        {
          "id": 1000000882,
          "name": "中濠悦际酒店（深圳宝安中心西乡店）",
          "upid": 24620
        },
        {
          "id": 1000000884,
          "name": "中濠国际酒店（贵阳大十字店）",
          "upid": 24622
        },
        {
          "id": 1000000885,
          "name": "中濠S酒店（深圳福田会展中心店）",
          "upid": 24615
        }
      ],
      dateTypeList: [
        {val: 1, name: "预定日期"},
        {val: 2, name: "入住日期"},
      ],
      payTypeList: [
        {val: 1, name: "支付宝"},
        {val: 2, name: "微信"},
        {val: 3, name: "现金"},
      ],
      channelList: [
        {val: 1, name: "抖音"},
        {val: 2, name: "携程"},
        {val: 3, name: "去哪儿"},
      ],
      orderStatusList: [
        {val: 1, name: "已成交"},
        {val: 2, name: "待付款"},
        {val: 3, name: "待收货"},
      ],
      orderNumberList: [
        {val: 1000000324, name: 1000000324},
        {val: 1000000325, name: 1000000325},
        {val: 1000000326, name: 1000000326},
        {val: 1000000327, name: 1000000327},
        {val: 1000000328, name: 1000000328},
        {val: 1000000329, name: 1000000329},
      ],
      querySearchData: {
        orderNumber: '',
        name: undefined,
        phone: undefined,
        dateType: 1,
        orderStatus: undefined,
        hotelIdList: [],
        payType: undefined,
        channel: undefined,
        dateList: [],
        page: {
          pageNum: 1,
          pageSize: 10,
        },
      },
      searchFormCollapleCode: `
        <template>
          <el-card shadow="never">
            <div class="conditionContainer lc-search-form-collapse">
              <div class="filter-margin">
                <el-select
                  popper-class="lc-select-popper"
                  :class="lc-select prefix-text-select remove-arrow one-word-prefix-input ' + (querySearchData.orderNumber ? 'hasData' : '')"
                  filterable
                  clearable
                  v-model="querySearchData.orderNumber"
                  suffix-icon=""
                  placeholder="查询订单号"
                >
                  <template slot="prefix">
                    <i class="el-icon-search"></i>
                  </template>
                  <el-option
                    v-for="item in orderNumberList"
                    :key="item.val"
                    :label="item.name"
                    :value="item.val"
                  />
                </el-select>
              </div>
              <div class="filter-margin">
                <el-select
                  popper-class="lc-select-popper"
                  :class="'lc-select prefix-icon-select four-word-prefix-input ' + (querySearchData.orderStatus ? 'hasData' : '')"
                  filterable 
                  clearable
                  v-model="querySearchData.orderStatus"
                  placeholder="请选择"
                >
                  <template slot="prefix">订单状态</template>
                  <el-option
                    v-for="item in orderStatusList"
                    :key="item.val"
                    :label="item.name"
                    :value="item.val"
                  />
                </el-select>
              </div>
              <div class="filter-margin">
                <el-select
                  popper-class="lc-select-popper"
                  class="lc-select has-right filterable"
                  filterable 
                  clearable
                  v-model="querySearchData.dateType"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in dateTypeList"
                    :key="item.val"
                    :label="item.name"
                    :value="item.val"
                  />
                </el-select>
                <el-date-picker
                  class="lc-date-picker has-left" 
                  popper-class="lc-date-picker" 
                  :style="{ width: '262px' }"
                  @change="chooseDate"
                  v-model="querySearchData.dateList"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="入住日期"
                  end-placeholder="离店日期"
                />
              </div>
              <div class="filter-margin">
                <el-select class="lc-select prefixText muiltSelect" popper-class="lc-select-popper" v-model="querySearchData.hotelIdList" :class="{'hasData': querySearchData.hotelIdList.length > 0}" multiple collapse-tags filterable clearable placeholder="全部">
                  <template slot="prefix">酒店名称/UPID</template>
                  <el-option v-for="item in selHotelList" :key="item.id" :value="item.id" :label="item.upid + ' ' + item.name" />
                </el-select>
              </div>
              <div class="filter-margin" v-show="showAllConditons">
                <el-input 
                  :class="'lc-input prefix-input four-word-prefix-input ' + (querySearchData.name ? 'hasData' : '')"
                  v-model="querySearchData.name" 
                  placeholder="请输入"
                >
                  <template slot="prefix">客人姓名</template>
                </el-input>
              </div>
              <div class="filter-margin" v-show="showAllConditons">
                <el-input 
                  :class="'lc-input prefix-input four-word-prefix-input ' + (querySearchData.phone ? 'hasData' : '')"
                  v-model="querySearchData.phone" 
                  placeholder="请输入"
                >
                  <template slot="prefix">客人电话</template>
                </el-input>
              </div>
              <div class="filter-margin" v-show="showAllConditons">
                <el-select
                  popper-class="lc-select-popper"
                  :class="'lc-select prefix-text-select four-word-prefix-input ' + (querySearchData.payType ? 'hasData' : '')"
                  filterable 
                  clearable
                  v-model="querySearchData.payType"
                  placeholder="请选择"
                >
                  <template slot="prefix">支付方式</template>
                  <el-option
                    v-for="item in payTypeList"
                    :key="item.val"
                    :label="item.name"
                    :value="item.val"
                  />
                </el-select>
              </div>
              <div class="filter-margin" v-show="showAllConditons">
                <el-select
                  popper-class="lc-select-popper"
                  :class="'lc-select prefix-text-select four-word-prefix-input ' + (querySearchData.orderStatus ? 'hasData' : '')"
                  filterable 
                  clearable
                  v-model="querySearchData.channel"
                  placeholder="请选择"
                >
                  <template slot="prefix">渠道</template>
                  <el-option
                    v-for="item in channelList"
                    :key="item.val"
                    :label="item.name"
                    :value="item.val"
                  />
                </el-select>
              </div>
              <div
                class="search-btn-container"
                :style="showAllConditons ? 'padding-top:0;margin-bottom:12px' : 'margin-bottom:12px'"
              >
                <el-button
                  class="lc-button"
                  type="primary"
                  @click="queryTableList"
                  size="mini"
                  v-preventReClick="2000"
                >
                  查询
                </el-button>
                <el-button
                  class="lc-button"
                  @click="resetSearchType"
                  size="mini"
                  type="info"
                >
                  重置
                </el-button>
                <el-link
                  :underline="false"
                  @click="showAllConditons = !showAllConditons"
                  style="margin: 0 8px 0 8px;position:relative"
                >
                  <template v-if="showAllConditons === true">
                    <span class="collapse-btn">收起</span>
                    <i class="el-icon-arrow-up"></i>
                  </template>
                  <template v-else>
                    <span class="expand-btn">展开</span>
                    <i class="el-icon-arrow-down"></i>
                  </template>
                </el-link>
              </div>
            </div>
          </el-card>
        </template>

      <script>
      export default {
        name: "CollapseVue",
        data() {
          return {
            showAllConditons: false,
            hotelIdList: [
              {val: 1, name: "UPID"},
              {val: 2, name: "携程ID"},
            ],
            selHotelList: [
              {
                "id": 210651,
                "name": "中濠嘉际酒店（东莞松山湖北站店）",
                "upid": 210651
              },
              {
                "id": 222115,
                "name": "中濠悦际酒店（深圳国际会展中心店）",
                "upid": 222115
              },
              {
                "id": 1000000876,
                "name": "中濠国际酒店（重庆华南城店）",
                "upid": 26261
              },
              {
                "id": 1000000878,
                "name": "中濠悦际酒店（深圳前海店）",
                "upid": 24616
              },
              {
                "id": 1000000879,
                "name": "中濠国际酒店（东莞长安万达广场店）",
                "upid": 24619
              },
              {
                "id": 1000000880,
                "name": "中濠国际酒店（深圳宝安石岩店）",
                "upid": 24618
              },
              {
                "id": 1000000881,
                "name": "中濠悦际酒店（深圳龙华大浪商业中心店）",
                "upid": 24621
              },
              {
                "id": 1000000882,
                "name": "中濠悦际酒店（深圳宝安中心西乡店）",
                "upid": 24620
              },
              {
                "id": 1000000884,
                "name": "中濠国际酒店（贵阳大十字店）",
                "upid": 24622
              },
              {
                "id": 1000000885,
                "name": "中濠S酒店（深圳福田会展中心店）",
                "upid": 24615
              }
            ],
            dateTypeList: [
              {val: 1, name: "预定日期"},
              {val: 2, name: "入住日期"},
            ],
            payTypeList: [
              {val: 1, name: "支付宝"},
              {val: 2, name: "微信"},
              {val: 3, name: "现金"},
            ],
            channelList: [
              {val: 1, name: "抖音"},
              {val: 2, name: "携程"},
              {val: 3, name: "去哪儿"},
            ],
            orderStatusList: [
              {val: 1, name: "已成交"},
              {val: 2, name: "待付款"},
              {val: 3, name: "待收货"},
            ],
            orderNumberList: [
              {val: 1000000324, name: 1000000324},
              {val: 1000000325, name: 1000000325},
              {val: 1000000326, name: 1000000326},
              {val: 1000000327, name: 1000000327},
              {val: 1000000328, name: 1000000328},
              {val: 1000000329, name: 1000000329},
            ],
            querySearchData: {
              orderNumber: '',
              name: undefined,
              phone: undefined,
              dateType: 1,
              orderStatus: undefined,
              hotelIdList: [],
              payType: undefined,
              channel: undefined,
              dateList: [],
              page: {
                pageNum: 1,
                pageSize: 10,
              },
            },
          }
        },
        methods: {
          chooseDate(value) {
            this.querySearchData.startTime = value[0];
            this.querySearchData.endTime = value[1];
          },
          resetSearchType() {
            this.querySearchData = {
              orderNumber: '',
              name: undefined,
              phone: undefined,
              dateType: 1,
              orderStatus: undefined,
              hotelIdList: [],
              payType: undefined,
              channel: undefined,
              dateList: [],
              page: {
                pageNum: 1,
                pageSize: 10,
              },
            };
          },
          queryTableList() {
            // 使用按钮搜索时，重置为1
            this.querySearchData.page.pageNum = 1;
          },
        },
      };
      <\/script>`
    }
  },
  methods: {
    chooseDate(value) {
      this.querySearchData.startTime = value[0];
      this.querySearchData.endTime = value[1];
    },
    resetSearchType() {
      this.querySearchData = {
        orderNumber: '',
        name: undefined,
        phone: undefined,
        dateType: 1,
        orderStatus: undefined,
        hotelIdList: [],
        payType: undefined,
        channel: undefined,
        dateList: [],
        page: {
          pageNum: 1,
          pageSize: 10,
        },
      };
    },
    queryTableList() {
      // 使用按钮搜索时，重置为1
      this.querySearchData.page.pageNum = 1;
    },
    copyElement() {
      copyText(this.searchFormCollapleCode, this)
    }
  },
};
</script>

<style lang="less" scoped>
.box-info-text {
  text-align: left;
}
</style>