<template>
  <div class="ui-main-box" style="text-align: left;">
    <div class="box-header">
      <strong>ToolTip 文字提示</strong>
    </div>
    <div class="box-info-text">
      注意：增加 <p> “popperClass="lcNewPopperClass" </p>。以下代码可复制后直接使用。
    </div>
    <div class="box-content">
      <div class="secondTitle">1、解释说明</div>
      <el-tooltip
        placement="top"
        effect="light"
        popperClass="lcNewTooltipClass"
        content="这是一段普通样式的tooltip样式">
        <div class="default"><i class="iconfont a-rezenonepc-Help"></i>指标说明</div>
      </el-tooltip>
      <div class="codeBlock">
        <el-button type="text" icon="el-icon-document-copy" @click="copyElement(elmentData)">复制代码</el-button>
        <div>
          <pre><code>{{elmentData}}</code></pre>
        </div>
      </div>
    </div>
    <div class="box-content">
      <div class="secondTitle">2、解释名词</div>
      <el-tooltip
        placement="top"
        popperClass="lcNewTooltipClass"
        effect="light"
        content="这是一段解释名词的tooltip样式">
        <div class="default noun"><i class="iconfont a-rezenonepc-Help"></i></div>
      </el-tooltip>
      <div class="codeBlock">
        <el-button type="text" icon="el-icon-document-copy" @click="copyElement(mounElmentData)">复制代码</el-button>
        <div>
          <pre><code>{{mounElmentData}}</code></pre>
        </div>
      </div>
    </div>
    <div class="box-content">
      <div class="secondTitle">3、解释功能</div>
      <el-tooltip
        placement="top"
        popperClass="lcNewTooltipClass"
        effect="light"
        content="这是一段解释功能的tooltip样式">
        <div class="default ability"><i class="iconfont a-rezenonepc-Information"></i></div>
      </el-tooltip>
      <div class="codeBlock">
        <el-button type="text" icon="el-icon-document-copy" @click="copyElement(abilityElmentData)">复制代码</el-button>
        <div>
          <pre><code>{{abilityElmentData}}</code></pre>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      elmentData: `
        <el-tooltip
          placement="top"
          popperClass="lcNewTooltipClass"
          effect="light"
          content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
          <div class="default"><i class="iconfont a-rezenonepc-Help"></i>指标说明</div>
        </el-tooltip>
      `,
      mounElmentData: `
        <el-tooltip
          placement="top"
          popperClass="lcNewTooltipClass"
          effect="light"
          content="这是一段解释名词的tooltip样式">
          <div class="default noun"><i class="iconfont a-rezenonepc-Help"></i></div>
        </el-tooltip>
      `,
      abilityElmentData: `
        <el-tooltip
          placement="top"
          popperClass="lcNewTooltipClass"
          effect="light"
          content="这是一段解释功能的tooltip样式">
          <div class="default ability"><i class="iconfont a-rezenonepc-Information"></i></div>
        </el-tooltip>
      `
    }
  },
  methods: {
    copyElement(val) {
      const nodeTextarea = document.createElement('textarea');
      // 隐藏该元素
      nodeTextarea.style.opacity = 0;
      // 写入元素内容（要复制的文本）
      nodeTextarea.value = val;
      // 将元素加入Dom中
      document.body.appendChild(nodeTextarea);
      // 选中该元素
      nodeTextarea.select();
      // 复制以选中元素的内容到粘贴板
      document.execCommand('Copy');
      // 复制完毕，移除该元素
      document.body.removeChild(nodeTextarea);
      this.$message.success('复制成功');
    }

  }
}
</script>
<style lang="less" scoped>

</style>
