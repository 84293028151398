<template>
  <div class="ui-main-box">
    <div class="box-header">
      <strong>内容区分页/锚点标签页</strong>
    </div>
    <div class="content-main">
      <div class="part">
        <div class="box-info-text">
          使用时需增加 <p> class= "lc-tab-box" </p>。以下代码可复制后直接使用。
        </div>
        <br>
        <div class="box-content">
          <div>组件示例</div>
          <div style="padding:16px 8px;background-color:#F2F3F5">
            <el-tabs class="lc-tab-box" v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="用户管理" name="first"></el-tab-pane>
              <el-tab-pane label="配置管理" name="second"></el-tab-pane>
              <el-tab-pane label="角色管理" name="third"></el-tab-pane>
              <el-tab-pane label="定时任务补偿" name="fourth"></el-tab-pane>
            </el-tabs>
          </div>
          <div class="codeBlock">
            <el-button type="text" icon="el-icon-document-copy" @click="copyElement(elmentData4)">复制代码</el-button>
            <div>
              <pre><code>{{elmentData4}}</code></pre>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-header">
      <strong>标题栏组合</strong>
    </div>
    <div class="box-content">
      <div class="content-main">
        <div class="part">
          <div class="secondTitle">组件传参</div>
          <div style="display:flex;align-items:flex-start"  class="box-info-text">
            <span>list [Array]</span>
            <div>
              标题栏数组，数组长度=1时展示为三级标题，>1时展示为四级导航。<br>
              [Array]元素格式：{name: '标题名称', path: '跳转路径'}，path仅在四级导航状态下生效
            </div>
          </div>
          <div class="box-info-text"><span>showBack [Boolean]</span> 在三级标题状态下判断是否需要展示返回按钮</div>
        </div>
        <div class="part">
          <div class="secondTitle">组件方法</div>
          <div class="box-info-text"><span>goBack</span> 三级标题状态下点击返回按钮执行的方法，若不传则默认返回前一页面</div>
        </div>
        <div class="part">
          <div class="secondTitle">组件使用示例</div>
          <div style="padding:2px 8px 16px;background-color:#F2F3F5">
            <div>
              <h4>单独三级标题</h4>
              <TitleBar :list="[{name: '三级标题'}]" :showBack="false"></TitleBar>
              <div class="codeBlock">
                <el-button type="text" icon="el-icon-document-copy" @click="copyElement(elmentData1)">复制代码</el-button>
                <div>
                  <pre><code>{{elmentData1}}</code></pre>
                </div>
              </div>
            </div>
            <div style="margin-top:20px">
              <h4>三级标题+返回</h4>
              <TitleBar :list="[{name: '三级标题'}]" :showBack="true"></TitleBar>
              <div class="codeBlock">
                <el-button type="text" icon="el-icon-document-copy" @click="copyElement(elmentData2)">复制代码</el-button>
                <div>
                  <pre><code>{{elmentData2}}</code></pre>
                </div>
              </div>
            </div>
            <div style="margin-top:20px">
              <h4>四级导航</h4>
              <TitleBar :list="[{name: '四级菜单1', path: '/path1'}, {name: '四级菜单2', path: '/path2'}, {name: '四级菜单3', path: '/path3'}]"></TitleBar>
              <div class="codeBlock">
                <el-button type="text" icon="el-icon-document-copy" @click="copyElement(elmentData3)">复制代码</el-button>
                <div>
                  <pre><code>{{elmentData3}}</code></pre>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br>
        <div>
          <div class="secondTitle">标题栏组件代码</div>
          <div class="box-info-text">
            该组件为自定义封装组件，已引入rezen-business-front-end-entry项目，business pc项目中可直接使用。<br>其他项目如需使用，需将该组件代码复制到对应entry项目中。</div>
          <div class="codeBlock">
            <el-button type="text" icon="el-icon-document-copy" @click="copyElement(elmentData)">复制代码</el-button>
            <div>
              <pre><code>{{elmentData}}</code></pre>
            </div>
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
</template>

<script>
import TitleBar from './titleBar.vue';

export default {
  components: {TitleBar},
  data() {
    return {
      visible: false,
      elmentData: `
       <template>
        <div class="lc-title-bar-box">
          <div class="top_nav_link">
            <template v-if="list.length<=1">
              <div style="display: flex;align-items: center;">
                <div @click="goBack" v-if="showBack" class="back-icon"><i style="font-size: 20px;margin-top: 4px;" class="el-icon-arrow-left"></i></div>
                <span>{{list[0] && list[0].name}}</span>
              </div>
            </template>
            <template v-else>
              <el-menu :default-active="activeIndex" mode="horizontal"  @select="handleSelect">
                <el-menu-item :key="index" v-for="(item, index) in list" :index="index + ''">{{item.name}}</el-menu-item>
              </el-menu>
            </template>
          </div>
        </div>
      </template>

      <script>
        export default {
          name: 'TitleBar',
          data() {
            return {
              activeIndex: '0'
            }
          },
          props: {
            list: {
              type: Array,
              default: () => []
            },
            showBack: {
              type: Boolean,
              default: true
            },
          },
          methods: {
            goBack() {
              if (this.$listeners.goBack) {
                this.$emit('goBack');
              } else {
                history.go(-1);
              }
            },
            handleSelect(index) {
              this.activeIndex = index;
              let item = this.list[index];
              this.$router.push(item.path);
            }
          }
        }
      <\/script>

      <style lang="less" scoped>
      .lc-title-bar-box {
        text-align: left;
        background-color: #fff;

        i:hover {
          color: #FFAD3D;
        }
        i:active {
          color: #FFAD3D;
        }

        .back-icon {
          cursor: pointer;
          margin-right: 8px;
          margin-left: -8px;
        }

        .top_nav_link{
          border: none;
          position: relative;
          margin:0 28px;
        }
        .top_nav_link::after {
          box-sizing: border-box;
          position: absolute;
          width: 100%;
          content: '';
          border-bottom: 1px solid #E3E3E3;
          padding: 0 5px 0 5px;
          bottom: 0;
          left:0;
        }
        span {
          color: #071022;
          font-size: 20px;
          font-family: Microsoft YaHei;
          display: inline-block;
          margin: 16px 36px 16px 0px;
          height: 26px;
          line-height: 26px;
          font-weight: 400;
          text-decoration: none;
          margin-bottom: 16px;
        }
        .el-menu-item {
          color: #5C5F66;
          font-size: 16px;
          font-family: Microsoft YaHei;
          display: inline-block;
          height: 56px !important;
          line-height: 56px !important;
          text-decoration: none;
          padding: 0;
          margin-right: 36px !important;
        }

        .el-menu-item:hover {
          color: #5C5F66;
          background-color: transparent !important;
          font-weight: 400;
          font-size: 16px;
        }

        .el-menu-item.is-active {
          color: unset;
          border-bottom: 3px solid #FFAD3D;
          font-weight: 700;
          background-color: transparent !important;
          font-size: 16px;
        }
      }
      <\/style>
      `,
      elmentData1: `
        <TitleBar
          :list="[{name: '三级标题'}]"
          :showBack="false">
        </TitleBar>
      `,
      elmentData2: `
        <TitleBar
          :list="[{name: '三级标题'}]"
          :showBack="true"
          @goBack="goBack">
        </TitleBar>
      `,
      elmentData3: `
        <TitleBar
          :list="[
            {name: '四级菜单1', path: '/path1'},
            {name: '四级菜单2', path: '/path2'},
            {name: '四级菜单3', path: '/path3'}
          ]">
        </TitleBar>
      `,
      elmentData4: `
        <el-tabs class="lc-tab-box" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="用户管理" name="first"></el-tab-pane>
          <el-tab-pane label="配置管理" name="second"></el-tab-pane>
          <el-tab-pane label="角色管理" name="third"></el-tab-pane>
          <el-tab-pane label="定时任务补偿" name="fourth"></el-tab-pane>
        </el-tabs>
      `,
      confirmTitle: '',
      confirmDesc: '',
      activeName: 'first'
    }
  },
  methods: {
    copyElement(val) {
      const nodeTextarea = document.createElement('textarea');
      // 隐藏该元素
      nodeTextarea.style.opacity = 0;
      // 写入元素内容（要复制的文本）
      nodeTextarea.value = val;
      // 将元素加入Dom中
      document.body.appendChild(nodeTextarea);
      // 选中该元素
      nodeTextarea.select();
      // 复制以选中元素的内容到粘贴板
      document.execCommand('Copy');
      // 复制完毕，移除该元素
      document.body.removeChild(nodeTextarea);
      this.$message.success('复制成功');
    },
    goBack() {
      this.$message.info('返回上一页');
    },
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style scoped>
.content-main {
  text-align: left;
  padding: 30px;
}

span {
  font-weight: bold;
  font-size: 14px;
  margin-right: 2px;
}

h4 {
  margin-bottom: 8px;
}

.part {
  margin-bottom: 40px;
}
</style>