export const copyText = (val, that) => {
  const nodeTextarea = document.createElement('textarea');
  // 隐藏该元素
  nodeTextarea.style.opacity = 0;
  // 写入元素内容（要复制的文本）
  nodeTextarea.value = val;
  // 将元素加入Dom中
  document.body.appendChild(nodeTextarea);
  // 选中该元素
  nodeTextarea.select();
  // 复制以选中元素的内容到粘贴板
  document.execCommand('Copy');
  // 复制完毕，移除该元素
  document.body.removeChild(nodeTextarea);
  console.log('复制成功');
  that.$message.success('复制成功');
}