import Vue from 'vue'
import App from './App.vue'
import './set-public-path';
import AvocadoLoadVue from 'avocado-load-vue';
import packageConfig from '../package.json';

Vue.config.productionTip = false;

// 使用Avocado创建生命周期对象
const vueLifecycles = AvocadoLoadVue({
  Vue,
  rootDomId: 'biz-app',
  // 应用节点名称
  appName: packageConfig.name,
  appOptions: {
    render: h => h(App)
  },
});
Vue.config.devtools = true;

// 找到已经加载的vue2的css文件，禁用掉，避免与当前样式冲突
const vue3Cssdom = document.querySelector(`link[href*="/crs/rezen-hq-vue2-comm/"]`);
vue3Cssdom.disabled = true;

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
export const { update } = vueLifecycles;