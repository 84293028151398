<template>
  <div class="ui-main-box">
    <div class="box-header">
      <strong>Checkbox 多选框</strong>
    </div>
    <div class="box-content">
      <div class="content-title">1、单个多选框</div>
      <div class="content-demo">
        <div v-for="item in checkList" class="content-item">
          <div class="content-desc">{{ item.desc }}</div>
          <el-checkbox
            class="lc-checkbox"
            v-model="item.checked"
            :disabled="item.disabled"
            >备选项</el-checkbox
          >
        </div>
      </div>
      <div class="codeBlock">
        <el-button
          type="text"
          icon="el-icon-document-copy"
          @click="copyElement(setpData1)"
          >复制代码</el-button
        >
        <div>
          <pre><code>{{ setpData1 }}</code></pre>
        </div>
      </div>
    </div>
    <div class="box-content">
      <div class="content-title">2、多选框组</div>
      <div class="content-demo">
        <el-checkbox-group v-model="checkListVal" class="lc-checkbox-group">
          <el-checkbox label="复选框 A"></el-checkbox>
          <el-checkbox label="复选框 B"></el-checkbox>
          <el-checkbox label="复选框 C"></el-checkbox>
          <el-checkbox label="禁用" disabled></el-checkbox>
          <el-checkbox label="选中且禁用" disabled></el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="codeBlock">
        <el-button
          type="text"
          icon="el-icon-document-copy"
          @click="copyElement(setpData2)"
          >复制代码</el-button
        >
        <div>
          <pre><code>{{ setpData2 }}</code></pre>
        </div>
      </div>
    </div>
    <div class="box-content">
      <div class="content-title">3、半选状态</div>
      <div class="content-demo">
        <el-checkbox
          class="lc-checkbox"
          :indeterminate="false"
          v-model="checkAllDis"
          disabled
          >禁用全选</el-checkbox
        >
        <el-checkbox
          class="lc-checkbox"
          :indeterminate="true"
          v-model="checkAllDis"
          disabled
          >禁用半选</el-checkbox
        >
      </div>
      <div class="content-demo">
        <el-checkbox
          class="lc-checkbox"
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <div style="margin: 0px 15px"></div>
        <el-checkbox-group
          class="lc-checkbox-group"
          v-model="checkListVal1"
          @change="handleCheckedCitiesChange"
        >
          <el-checkbox v-for="city in cities" :label="city" :key="city">{{
            city
          }}</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="codeBlock">
        <el-button
          type="text"
          icon="el-icon-document-copy"
          @click="copyElement(setpData3)"
          >复制代码</el-button
        >
        <div>
          <pre><code>{{ setpData3 }}</code></pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      setpData1: `    
      <el-checkbox class="lc-checkbox" v-model="checked" :disabled="disabled">备选项</el-checkbox>
      `,
      setpData2: `    
      <el-checkbox-group v-model="checkListVal" class="lc-checkbox-group">
        <el-checkbox label="复选框 A"></el-checkbox>
        <el-checkbox label="复选框 B"></el-checkbox>
        <el-checkbox label="复选框 C"></el-checkbox>
        <el-checkbox label="禁用" disabled></el-checkbox>
        <el-checkbox label="选中且禁用" disabled></el-checkbox>
      </el-checkbox-group>
      `,
      setpData3: `    
        <el-checkbox class="lc-checkbox" :indeterminate="false" v-model="checkAllDis" disabled>禁用全选</el-checkbox>
        
        <el-checkbox class="lc-checkbox" :indeterminate="true" v-model="checkAllDis" disabled>禁用半选</el-checkbox>
        
        <el-checkbox class="lc-checkbox" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <el-checkbox-group class="lc-checkbox-group" v-model="checkListVal1" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="city in cities" :label="city" :key="city">{{ city }}</el-checkbox>
        </el-checkbox-group>
      `,
      checkList: [
        { desc: "选中状态", checked: true, disabled: false },
        { desc: "未选状态", checked: false, disabled: false },
        { desc: "鼠标移入", checked: false, disabled: false },
        { desc: "选中状态下禁用", checked: true, disabled: true },
        { desc: "未选状态下禁用", checked: false, disabled: true },
      ],
      cities: ["上海", "北京", "广州", "深圳"],
      checkListVal: ["复选框 A", "复选框 B", "选中且禁用"],
      checkListVal1: ["上海", "北京"],
      isIndeterminate: true,
      checkAll: true,
      checkAllDis: true,
      checkHalfDis: false,
    };
  },
  created() {},
  methods: {
    handleCheckAllChange(val) {
      this.checkListVal1 = val ? this.cities : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },
    copyElement(val) {
      const nodeTextarea = document.createElement("textarea");
      // 隐藏该元素
      nodeTextarea.style.opacity = 0;
      // 写入元素内容（要复制的文本）
      nodeTextarea.value = val;
      // 将元素加入Dom中
      document.body.appendChild(nodeTextarea);
      // 选中该元素
      nodeTextarea.select();
      // 复制以选中元素的内容到粘贴板
      document.execCommand("Copy");
      // 复制完毕，移除该元素
      document.body.removeChild(nodeTextarea);
      this.$message.success("复制成功");
    },
  },
};
</script>

<style lang="less" scoped>
.box-content {
  .content-title {
    text-align: left;
    padding: 20px 0;
    font-size: 24px;
    color: #000;
  }
  .content-demo {
    display: flex;
    margin-bottom: 10px;
    .content-item {
      // width: 300px;
      margin-right: 100px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    .content-desc {
      // width: 100px;
      text-align: left;
      margin-bottom: 10px;
    }
  }
}
</style>
