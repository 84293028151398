<template>
  <div class="ui-main-box">
    <div class="box-header">
      <strong>Table 列表/表格</strong>
    </div>
    <div class="box-info-text">注意：</div>
    <div class="box-info-text">1、无边框只需去掉border属性；</div>
    <div class="box-info-text">2、使用拖拽功能需确保项目中的iconfont有拖拽icon（ic_sort）；</div>
    <div class="box-info-text">3、列一直高亮需加类名“class=cell-always-highlight”；</div>
    <div class="box-info-text">4、内容超出省略提示弹窗需增加属性tooltip-effect="light lcNewTooltipClass"，在列上增加属性show-overflow-tooltip</div>
    <div class="box-content">
      <div>普通表格</div>
      <div class="lc-table">
        <el-table id="table-border" :data="pageComponentsRelationList" border row-key="id" tooltip-effect="light lcNewTooltipClass">
          <el-table-column width="48px">
            <i class="iconfont ic_sort"></i>
          </el-table-column>
          <el-table-column label="ID" prop="id" width="60px" sortable></el-table-column>
          <el-table-column label="组件类型">
            <template slot-scope="scope">
              <div class="cell-always-highlight">{{ scope.row.componentCategory }}</div>
            </template>
          </el-table-column>
          <el-table-column label="组件名称" width="188px" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column label="上线/下线" prop="status" align="center" width="100px">
            <template slot-scope="scope">
              <el-switch
                  class="lc-switch"
                  v-model="scope.row.status"
                  :active-value="1"
                  :inactive-value="0"
                  :disabled="scope.row.disabled">
                </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="260">
            <template slot-scope="scope">
              <el-button-group class="lc-button-group">
                <el-button class="lc-button lc-button-icon" type="text"><i class="iconfont Business-PC-Edit" /></el-button>
                <el-button class="lc-button lc-button-icon lc-button-icon-last" type="text"><i class="iconfont Business-PC-Delete" /></el-button>
                <el-button class="lc-button" type="text">积分间夜明细</el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="codeBlock">
        <el-button type="text" icon="el-icon-document-copy" @click="copyElement(commonTableElmentData)">复制代码</el-button>
        <div>
          <pre><code>{{ commonTableElmentData }}</code></pre>
        </div>
      </div>
    </div>
    <div class="box-content">
      <div>可编辑表格</div>
      <div class="lc-table">
        <el-table :data="pageComponentsRelationList" row-key="id">
          <el-table-column label="组件类型" width="120px">
            <template slot-scope="scope">
              <div class="cell-always-highlight">{{ scope.row.componentCategory }}</div>
            </template>
          </el-table-column>
          <el-table-column label="可领取数量" prop="getNum">
            <template slot-scope="scope">
              <el-input
                        class="lc-input"
                        v-model="scope.row.getNum"
                        onkeyup="this.value=this.value.replace(/\D/g,'')"
                        placeholder="请输入"
              />
            </template>
          </el-table-column>
          <el-table-column label="可领取会员等级" prop="rankLevels">
            <template slot-scope="scope">
              <el-select class="lc-select" popper-class="lc-select-popper" v-model="scope.row.rankLevels" multiple collapse-tags placeholder="请选择">
                <el-option  v-for="sItem in rankList"
                            :value="sItem.rankLevel"
                            :label="sItem.rankName"
                            :key="sItem.rankLevel">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button-group class="lc-button-group">
                <el-button class="lc-button lc-button-icon lc-button-icon-last" type="text"><i class="iconfont Business-PC-Edit" /></el-button>
                <el-button class="lc-button" type="text">添加</el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="codeBlock">
        <el-button type="text" icon="el-icon-document-copy" @click="copyElement(editTableElmentData)">复制代码</el-button>
        <div>
          <pre><code>{{ editTableElmentData }}</code></pre>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sortable from 'sortablejs'
export default {
  data() {
    return {
      pageComponentsRelationList: [
        {
          id: 1,
          componentCategory: '优惠券组件',
          name: '123优惠',
          rankLevels: [],
          getNum: '',
          status: 1,
          disabled: true
        },
        {
          id: 2,
          componentCategory: '图片组件',
          name: '上传图片给你加拿大快',
          rankLevels: [],
          getNum: '',
          status: 0,
          disabled: false
        },
        {
          id: 3,
          componentCategory: '商城组件',
          name: '上传商城肺宁颗粒你发了文法苏宁卡理发你发',
          rankLevels: [],
          getNum: '',
          status: 1,
          disabled: false
        }
      ],
      rankList: [
        {
          "id": 3,
          "rankName": "轻奢菁英卡",
          "rankLevel": 1000,
        },
        {
          "id": 4,
          "rankName": "金钻菁英卡",
          "rankLevel": 2000,
        },
        {
          "id": 5,
          "rankName": "黑钻菁英卡",
          "rankLevel": 3000,
        },
        {
          "id": 6,
          "rankName": "传奇菁英卡",
          "rankLevel": 4000,
        }
      ],
      commonTableElmentData: `
        <template>
          <div class="lc-table">
            <el-table id="table-border" :data="pageComponentsRelationList" border row-key="id" tooltip-effect="light lcNewTooltipClass">
              <el-table-column width="52px">
                <i class="iconfont ic_sort"></i>
              </el-table-column>
              <el-table-column label="ID" prop="id" width="70px" sortable></el-table-column>
              <el-table-column label="组件类型">
                <template slot-scope="scope">
                  <div class="cell-always-highlight">{{ scope.row.componentCategory }}</div>
                </template>
              </el-table-column>
              <el-table-column label="组件类型副本">
                <template slot-scope="scope">
                  <div class="cell-hover-highlight">{{ scope.row.componentCategory }}</div>
                </template>
              </el-table-column>
              <el-table-column label="组件名称" width="188px" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.name }}
                </template>
              </el-table-column>
              <el-table-column label="上线/下线" prop="status" align="center" width="100px">
                <template slot-scope="scope">
                  <el-switch
                      class="lc-switch"
                      v-model="scope.row.status"
                      :active-value="1"
                      :inactive-value="0"
                      :disabled="scope.row.disabled">
                    </el-switch>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="260">
                <template slot-scope="scope">
                  <el-button-group class="lc-button-group">
                    <el-button class="lc-button lc-button-icon" type="text"><i class="iconfont Business-PC-Edit" /></el-button>
                    <el-button class="lc-button lc-button-icon lc-button-icon-last" type="text"><i class="iconfont Business-PC-Delete" /></el-button>
                    <el-button class="lc-button" type="text">积分间夜明细</el-button>
                  </el-button-group>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
        <script>
          import Sortable from 'sortablejs'
          export default {
            data() {
              return {
                pageComponentsRelationList: [
                  {
                    id: 1,
                    componentCategory: '优惠券组件',
                    name: '123优惠'
                  },
                  {
                    id: 2,
                    componentCategory: '图片组件',
                    name: '123图片'
                  },
                  {
                    id: 3,
                    componentCategory: '商城组件',
                    name: '123上传商城123上传商城123上传商城123上传商城123上传商城123上传商城'
                  }
                ],
              },
            },
            mounted() {
              this.rowDrop()
            },
            methods: {
              rowDrop() {
                // 拖拽逻辑
                const borderTBody = document.querySelector('#table-border .el-table__body-wrapper tbody')
                if (borderTBody) {
                  Sortable.create(borderTBody, {
                    animation: 150,
                    handle: '.ic_sort', // 这个class类名不能更换
                    onEnd: ({newIndex, oldIndex}) => {
                      if (newIndex == oldIndex) {
                        return
                      }
                      // 在这处理后端逻辑
                    }
                  })
                }
              }
            }
          }
        <\/script>
      `,
      editTableElmentData: `
        <template>
          <div class="lc-table">
            <el-table :data="pageComponentsRelationList" row-key="id">
              <el-table-column label="组件类型" width="120px">
                <template slot-scope="scope">
                  <div class="cell-always-highlight">{{ scope.row.componentCategory }}</div>
                </template>
              </el-table-column>
              <el-table-column label="可领取数量" prop="getNum">
                <template slot-scope="scope">
                  <el-input
                            class="lc-input"
                            v-model="scope.row.getNum"
                            onkeyup="this.value=this.value.replace(/\D/g,'')"
                            placeholder="请输入"
                  />
                </template>
              </el-table-column>
              <el-table-column label="可领取会员等级" prop="rankLevels">
                <template slot-scope="scope">
                  <el-select class="lc-select" popper-class="lc-select-popper" v-model="scope.row.rankLevels" multiple collapse-tags placeholder="请选择">
                    <el-option  v-for="sItem in rankList"
                                :value="sItem.rankLevel"
                                :label="sItem.rankName"
                                :key="sItem.rankLevel">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="80">
                <template slot-scope="scope">
                  <el-button-group class="lc-button-group">
                    <el-button class="lc-button lc-button-icon" type="text"><i class="iconfont Business-PC-Edit" /></el-button>
                    <el-button class="lc-button" type="text">添加</el-button>
                  </el-button-group>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
        <script>
          export default {
            data() {
              return {
                pageComponentsRelationList: [
                  {
                    id: 1,
                    componentCategory: '优惠券组件',
                    name: '123优惠',
                    rankLevels: [],
                    getNum: ''
                  },
                  {
                    id: 2,
                    componentCategory: '图片组件',
                    name: '123图片',
                    rankLevels: [],
                    getNum: ''
                  },
                  {
                    id: 3,
                    componentCategory: '商城组件',
                    name: '123上传商城123上传商城123上传商城123上传商城123上传商城123上传商城',
                    rankLevels: [],
                    getNum: ''
                  }
                ],
                rankList: [
                  {
                    "id": 3,
                    "rankName": "轻奢菁英卡",
                    "rankLevel": 1000,
                  },
                  {
                    "id": 4,
                    "rankName": "金钻菁英卡",
                    "rankLevel": 2000,
                  },
                  {
                    "id": 5,
                    "rankName": "黑钻菁英卡",
                    "rankLevel": 3000,
                  },
                  {
                    "id": 6,
                    "rankName": "传奇菁英卡",
                    "rankLevel": 4000,
                  }
                ],
              },
            },
          }
        <\/script>
      `,
    }
  },
  mounted() {
    this.rowDrop()
  },
  methods: {
    rowDrop() {
      const borderTBody = document.querySelector('#table-border .el-table__body-wrapper tbody')
      if (borderTBody) {
        Sortable.create(borderTBody, {
          animation: 150,
          handle: '.ic_sort',
          onEnd: ({newIndex, oldIndex}) => {
            if (newIndex == oldIndex) {
              return
            }
          }
        })
      }
    },
    copyElement(val) {
      const nodeTextarea = document.createElement('textarea');
      // 隐藏该元素
      nodeTextarea.style.opacity = 0;
      // 写入元素内容（要复制的文本）
      nodeTextarea.value = val;
      // 将元素加入Dom中
      document.body.appendChild(nodeTextarea);
      // 选中该元素
      nodeTextarea.select();
      // 复制以选中元素的内容到粘贴板
      document.execCommand('Copy');
      // 复制完毕，移除该元素
      document.body.removeChild(nodeTextarea);
      this.$message.success('复制成功');
    }
  }
}
</script>
<style lang="less">
.text-overhidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>