<template>
  <div class="ui-main-box">
    <div class="box-header">
      <strong>Step 步骤</strong>
    </div>
    <div style="width: 70%; padding: 24px;">
      <el-steps class="lc-step" :active="active">
        <el-step v-for="(item, index) in stepList" :title="item.title" :key="index">
          <div slot="icon" class="iconfont" :class="index<active-1?'a-CombinedShape':'waiting'"></div>
          <div slot="description">
            <div v-for="dItem in item.descriptions">{{dItem}}</div>
          </div>
        </el-step>
      </el-steps>
      <div class="codeBlock">
        <el-button type="text" icon="el-icon-document-copy" @click="copyElement(setpData)">复制代码</el-button>
        <div>
          <pre><code>{{ setpData }}</code></pre>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 2,
      setpData: `
<el-steps class="lc-step" :active="active">
        <el-step v-for="(item, index) in stepList" :title="item.title" :key="index">
          <div slot="icon" class="iconfont" :class="index<active-1?'a-CombinedShape':'waiting'"></div>
          <div slot="description">
            <div v-for="dItem in item.descriptions">{{dItem}}</div>
          </div>
        </el-step>
</el-steps>`,
      stepList: [
        {
          title: '提交',
          descriptions: [
              '提交人：张海双',
              '提交时间：2024-04-22 12:35:11'
          ]
        },{
          title: '运营评估',
          descriptions: [
            '等待审核'
          ]
        },{
          title: '完成'
        }
      ]
    }
  },
  created() {
  },
  methods: {
    copyElement(val) {
      const nodeTextarea = document.createElement('textarea');
      // 隐藏该元素
      nodeTextarea.style.opacity = 0;
      // 写入元素内容（要复制的文本）
      nodeTextarea.value = val;
      // 将元素加入Dom中
      document.body.appendChild(nodeTextarea);
      // 选中该元素
      nodeTextarea.select();
      // 复制以选中元素的内容到粘贴板
      document.execCommand('Copy');
      // 复制完毕，移除该元素
      document.body.removeChild(nodeTextarea);
      this.$message.success('复制成功');
    }
  }
}
</script>

<style lang="less" scoped>
</style>
