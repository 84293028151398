<template>
  <div class="ui-main-box">
    <div class="box-header">
      <strong>Radio 单选框</strong>
    </div>
    <div class="box-content">
      <div class="content-title">1、单个单选框</div>
      <div class="content-demo">
        <div v-for="item in checkList" class="content-item">
          <div class="content-desc">{{ item.desc }}</div>
          <el-radio
            class="lc-radio"
            v-model="item.checked"
            :disabled="item.disabled"
            :label="item.label"
            >备选项</el-radio
          >
        </div>
      </div>
      <div class="codeBlock">
        <el-button
          type="text"
          icon="el-icon-document-copy"
          @click="copyElement(setpData1)"
          >复制代码</el-button
        >
        <div>
          <pre><code>{{ setpData1 }}</code></pre>
        </div>
      </div>
    </div>
    <div class="box-content">
      <div class="content-title">2、单选框组</div>
      <div class="content-demo">
        <el-radio-group v-model="radio" class="lc-radio-group">
          <el-radio :label="3">备选项1</el-radio>
          <el-radio :label="6">备选项2</el-radio>
          <el-radio :label="9">备选项3</el-radio>
          <el-radio :label="12" disabled>禁用选项</el-radio>
        </el-radio-group>
      </div>
      <div class="codeBlock">
        <el-button
          type="text"
          icon="el-icon-document-copy"
          @click="copyElement(setpData2)"
          >复制代码</el-button
        >
        <div>
          <pre><code>{{ setpData2 }}</code></pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      setpData1: `    
      <el-radio class="lc-radio" v-model="checked" :disabled="disabled" :label="label">备选项</el-radio>
      `,
      setpData2: `    
        <el-radio-group v-model="radio" class="lc-radio-group">
          <el-radio :label="3">备选项1</el-radio>
          <el-radio :label="6">备选项2</el-radio>
          <el-radio :label="9">备选项3</el-radio>
          <el-radio :label="12" disabled>禁用选项</el-radio>
        </el-radio-group>
      `,
      checkList: [
        { desc: "选中状态", checked: 1, disabled: false, label: 1 },
        { desc: "未选状态", checked: "", disabled: false, label: 2 },
        { desc: "鼠标移入", checked: "", disabled: false, label: 3 },
        { desc: "选中状态下禁用", checked: 4, disabled: true, label: 4 },
        { desc: "未选状态下禁用", checked: "", disabled: true, label: 5 },
      ],
      radio: 3,
    };
  },
  created() {},
  methods: {
    copyElement(val) {
      const nodeTextarea = document.createElement("textarea");
      // 隐藏该元素
      nodeTextarea.style.opacity = 0;
      // 写入元素内容（要复制的文本）
      nodeTextarea.value = val;
      // 将元素加入Dom中
      document.body.appendChild(nodeTextarea);
      // 选中该元素
      nodeTextarea.select();
      // 复制以选中元素的内容到粘贴板
      document.execCommand("Copy");
      // 复制完毕，移除该元素
      document.body.removeChild(nodeTextarea);
      this.$message.success("复制成功");
    },
  },
};
</script>

<style lang="less" scoped>
.box-content {
  .content-title {
    text-align: left;
    padding: 20px 0;
    font-size: 24px;
    color: #000;
  }
  .content-demo {
    display: flex;
    .content-item {
      // width: 300px;
      margin-right: 100px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    .content-desc {
      // width: 100px;
      text-align: left;
      margin-bottom: 10px;
    }
  }
}
</style>
