<template>
  <div class="ui-main-box">
    <div class="box-header">
      <strong>Upload 上传</strong>
    </div>
    <div class="box-main">
      <div class="box-content">
        <div class="list-head">上传文件</div>
        <div class="text-show">
          <el-upload
              class="lc-upload"
              action="https://jsonplaceholder.typicode.com/posts/"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              multiple
              :limit="3"
              :on-exceed="handleExceed"
              :file-list="fileList">
            <el-button class="lc-button" type="info" icon="el-icon-plus">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </div>
        <div class="codeBlock" style="width: calc(100% - 80px);">
          <el-button type="text" icon="el-icon-document-copy" @click="copyElement(uploadData1)">复制代码</el-button>
          <div>
            <pre><code>{{ uploadData1 }}</code></pre>
          </div>
        </div>
      </div>
      <div class="box-content">
        <div class="list-head">上传图片</div>
        <div class="text-show">
          <el-upload
              class="lc-upload"
              action="https://jsonplaceholder.typicode.com/posts/"
              list-type="picture-card"
              :file-list="fileList"
              :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
              <div class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                  <i class="el-icon-view"></i>
                </span>
                <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                  <i class="el-icon-delete"></i>
                </span>
              </div>
            </div>
            <div class="el-upload__tip" slot="tip">格式：支持jpg、jpeg、png，分辨率大小600x600px</div>
          </el-upload>
        </div>
        <div class="codeBlock">
          <el-button type="text" icon="el-icon-document-copy" @click="copyElement(uploadData2)">复制代码</el-button>
          <div>
            <pre><code>{{ uploadData2 }}</code></pre>
          </div>
        </div>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: '',
      uploadData1: `
      <el-upload
              class="lc-upload"
              action="https://jsonplaceholder.typicode.com/posts/"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              multiple
              :limit="3"
              :on-exceed="handleExceed"
              :file-list="fileList">
            <el-button class="lc-button" type ="info">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
      </el-upload>
      `,
      uploadData2: `
      <el-upload
              class="lc-upload"
              action="https://jsonplaceholder.typicode.com/posts/"
              list-type="picture-card"
              :file-list="fileList"
              :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
              <span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                  <i class="el-icon-view"></i>
                </span>
                <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
            <div class="el-upload__tip" slot="tip">格式：支持jpg、jpeg、png，分辨率大小600x600px</div>
      </el-upload>`,
      fileList: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}]
    }
  },
  created() {
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    copyElement(val) {
      const nodeTextarea = document.createElement('textarea');
      // 隐藏该元素
      nodeTextarea.style.opacity = 0;
      // 写入元素内容（要复制的文本）
      nodeTextarea.value = val;
      // 将元素加入Dom中
      document.body.appendChild(nodeTextarea);
      // 选中该元素
      nodeTextarea.select();
      // 复制以选中元素的内容到粘贴板
      document.execCommand('Copy');
      // 复制完毕，移除该元素
      document.body.removeChild(nodeTextarea);
      this.$message.success('复制成功');
    }
  }
}
</script>

<style lang="less" scoped>
.box-main {
  .box-content {
    margin: 40px;
    padding-bottom: 40px;
    border-bottom: solid 1px #D8D8D8;
    width: 100%;


    .list-head {

      display: block;
      font-family: PingFangSC-Semibold;
      font-size: 36px;
      color: #0F2347;
      letter-spacing: 0;
      line-height: 36px;
      font-weight: 600;

      text-align: left;
    }
    .text-show {
      margin: 40px;
      text-align: left;
    }
  }


}
</style>
