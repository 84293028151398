<template>
  <div v-if="showView">
    <EntryVue :hashKey="hashKey"></EntryVue>
  </div>
</template>

<script>
import EntryVue from './views/entry.vue'

export default {
  name: 'App',
  components: {
    EntryVue
  },
  data: () => {
    return {
      showView: false,
      hashKey: ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      console.log(window.location.pathname)
      this.showView = window.location.pathname === '/design';
      let vdom = document.getElementById('crs-main');
      let cdom = document.getElementById('crs-comm');
      if(this.showView){
        vdom.style.display = 'none';
        cdom.style.display = 'none';
      } else {
        vdom.style.display = 'block';
        cdom.style.display = 'block';
      }
      this.hashKey = window.location.hash;
    });
  }
}
</script>

<style lang="less">
@import url("./less/index.less");
@import url("./less/businessComm.less");
@import url("./assets/iconfont.css");
</style>
