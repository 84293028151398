<template>


  <div class="ui-main-box">
    <div class="box-header">
      <strong>06. 按钮交互规范</strong>
    </div>
    <div class="box-main">
      <div class="box-content">
        <div class="secondTitle">1、内容区</div>
        <div class="box-info-text">内容区按钮: 直接使用type="text"; 内容区组合按钮: 使用el-button-group</div>
        <div class="text-show">
          <div>
            <span>默认状态：</span>
            <el-button class="lc-button" type="text" icon="el-icon-star-off">下载标准模板</el-button>
            <el-button class="lc-button" type="text" icon="el-icon-star-off">导入</el-button>
            <el-button class="lc-button" type="text" icon="el-icon-star-off">导出</el-button>
          </div>
          <span>组合状态：</span>
          <span style="font-size: 0;">
            <el-button-group class="lc-button-group" style="width: 500px;">
              <el-button class="lc-button" type="text" icon="el-icon-star-off">下载标准模板</el-button>
              <el-button class="lc-button" type="text" icon="el-icon-star-off">导入</el-button>
              <el-button class="lc-button" type="text" icon="el-icon-star-off">导出</el-button>
            </el-button-group>
          </span>
        </div>

        <div class="codeBlock">
          <el-button type="text" icon="el-icon-document-copy" @click="copyElement(elmentTextBtn)">复制代码</el-button>
          <div>  <pre><code>{{elmentTextBtn}}</code></pre> </div>
        </div>

      </div>

      <div class="box-content">
        <div class="secondTitle">2、列表页操作区</div>
        <div class="box-info-text">这里要区分纯icon按钮，和纯文本按钮。因此纯icon按钮要加上lc-button-icon； 组合按钮组中icon按钮和文本按钮同时使用时， 要给icon按钮加上lc-button-icon-last</div>
        <el-table :data="tableData" border style="width: 900px" >
          <el-table-column type="index" width="50" />
          <el-table-column label="默认状态" width="400">
            <template #default="scope">
              <el-button class="lc-button lc-button-icon" type="text" icon="el-icon-star-off"></el-button>
              <el-button class="lc-button lc-button-icon" type="text" icon="el-icon-edit"></el-button>
              <el-button class="lc-button" type="text">新建附加报告</el-button>
              <el-button class="lc-button" type="text">+添加附加信息</el-button>
            </template>
          </el-table-column>
          <el-table-column label="组合状态" width="400">
            <span style="font-size: 0;">
              <el-button-group class="lc-button-group" style="width: 500px;">
                <el-button class="lc-button lc-button-icon" type="text" icon="el-icon-star-off"></el-button>
                <el-button class="lc-button lc-button-icon lc-button-icon-last" type="text" icon="el-icon-edit"></el-button>
                <el-button class="lc-button" type="text">新建附加报告</el-button>
                <el-button class="lc-button" type="text">+添加附加信息</el-button>
                <el-button class="lc-button lc-button-icon" type="text" icon="el-icon-edit"></el-button>
              </el-button-group>
            </span>
          </el-table-column>
        </el-table>

        <div class="codeBlock">
          <el-button type="text" icon="el-icon-document-copy" @click="copyElement(elmentTableBtn)">复制代码</el-button>
          <div>  <pre><code>{{elmentTableBtn}}</code></pre> </div>
        </div>
      </div>

      <div class="box-content">
        <div class="secondTitle">3、按钮交互</div>
        <div class="box-info-text">主按钮使用type="primary; 次按钮不适用任何type; 辅助按钮使用type="info"; 禁用按钮type="close"</div>
        <div class="text-show">
          <div>
            <span>默认：</span>
            <span style="font-size: 0;">
              <el-button class="lc-button" type="primary">主按钮</el-button>
              <el-button class="lc-button">次按钮</el-button>
              <el-button class="lc-button" type="info">辅助按钮</el-button>
              <el-button class="lc-button" type="close" icon="el-icon-close"></el-button>
            </span>

          </div>
          <div style="margin: 10px 0;">
            <span>禁用：</span>
            <span style="font-size: 0;">
              <el-button class="lc-button" type="primary" disabled>主按钮</el-button>
              <el-button class="lc-button" disabled>次按钮</el-button>
              <el-button class="lc-button" type="info" disabled>辅助按钮</el-button>
              <el-button class="lc-button" type="close" icon="el-icon-close"></el-button>
            </span>
          </div>

        </div>

        <div class="codeBlock">
          <el-button type="text" icon="el-icon-document-copy" @click="copyElement(elmentBtn)">复制代码</el-button>
          <div>  <pre><code>{{elmentBtn}}</code></pre> </div>
        </div>

      </div>

      <div class="box-content">
        <div class="secondTitle">4、弹窗内按钮大小</div>
        <div class="box-info-text">固定宽度加上  style="width: 88px;"</div>
        <div class="text-show">
          <span>固定宽度88px：</span>
          <span style="font-size: 0;">
            <el-button class="lc-button" type="primary" style="width: 88px;">确定</el-button>
            <el-button class="lc-button" type="info" style="width: 88px;">取消</el-button>
          </span>
        </div>

        <div class="codeBlock">
          <el-button type="text" icon="el-icon-document-copy" @click="copyElement(elmentFixedBtn)">复制代码</el-button>
          <div>  <pre><code>{{elmentFixedBtn}}</code></pre> </div>
        </div>

      </div>

      <div class="box-content">
        <div class="secondTitle">5、切换按钮（图表）</div>
        <div class="box-info-text">切换按钮要多加上lc-btn-group-switch</div>
        <div class="text-show" style="width: 400px;">
          <el-button-group class="lc-button-group lc-btn-group-switch">
            <el-button class="lc-button">OTA营收占比</el-button>
            <el-button class="lc-button">RGI</el-button>
            <el-button class="lc-button">COCC</el-button>
          </el-button-group>
        </div>

        <div class="codeBlock">
          <el-button type="text" icon="el-icon-document-copy" @click="copyElement(elmentSwitchBtn)">复制代码</el-button>
          <div>  <pre><code>{{elmentSwitchBtn}}</code></pre> </div>
        </div>
      </div>

    </div>


  </div>
</template>

<script>
import { copyText } from '../../utils/index'
export default {
  data() {
    return {
      tableData: [
        {
          name: '项目1',
          status: '待处理'
        },
        {
          name: '项目2',
          status: '进行中'
        },
      ],
      elmentTextBtn: `
          <el-button class="lc-button" type="text" icon="el-icon-star-off">下载标准模板</el-button>
          <el-button class="lc-button" type="text" icon="el-icon-star-off">导入</el-button>
          <el-button class="lc-button" type="text" icon="el-icon-star-off">导出</el-button>


          <el-button-group class="lc-button-group" style="width: 500px;">
            <el-button class="lc-button" type="text" icon="el-icon-star-off">下载标准模板</el-button>
            <el-button class="lc-button" type="text" icon="el-icon-star-off">导入</el-button>
            <el-button class="lc-button" type="text" icon="el-icon-star-off">导出</el-button>
          </el-button-group>
   
      `,
      elmentTableBtn:`
       <el-table :data="tableData" style="width: 100%">
          <el-table-column type="index" width="50" />
          <el-table-column label="默认状态" width="500">
            <template #default="scope">
              <el-button class="lc-button lc-button-icon" type="text" icon="el-icon-star-off"></el-button>
              <el-button class="lc-button lc-button-icon" type="text" icon="el-icon-edit"></el-button>
              <el-button class="lc-button" type="text">新建附加报告</el-button>
              <el-button class="lc-button" type="text">+添加附加信息</el-button>
            </template>
          </el-table-column>
          <el-table-column label="组合状态" width="500">
            <span style="font-size: 0;">
              <el-button-group class="lc-button-group" style="width: 500px;">
                <el-button class="lc-button lc-button-icon" type="text" icon="el-icon-star-off"></el-button>
                <el-button class="lc-button lc-button-icon lc-button-icon-last" type="text" icon="el-icon-edit"></el-button>
                <el-button class="lc-button" type="text">新建附加报告</el-button>
                <el-button class="lc-button" type="text">+添加附加信息</el-button>
                <el-button class="lc-button lc-button-icon" type="text" icon="el-icon-edit"></el-button>
              </el-button-group>
            </span>
          </el-table-column>
        </el-table>
      `,
      elmentBtn: `
              <el-button class="lc-button" type="primary">主按钮</el-button>
              <el-button class="lc-button">次按钮</el-button>
              <el-button class="lc-button" type="info">辅助按钮</el-button>
              <el-button class="lc-button" type="close" icon="el-icon-close"></el-button>

              <el-button class="lc-button" type="primary" disabled>主按钮</el-button>
              <el-button class="lc-button" disabled>次按钮</el-button>
              <el-button class="lc-button" type="info" disabled>辅助按钮</el-button>
              <el-button class="lc-button" type="close" icon="el-icon-close"></el-button>
      `,
      elmentFixedBtn: `
            <el-button class="lc-button" type="primary" style="width: 88px;">确定</el-button>
            <el-button class="lc-button" type="info" style="width: 88px;">取消</el-button>
      `,
      elmentSwitchBtn: `
        <div class="text-show" style="width: 400px;">
          <el-button-group class="lc-button-group lc-btn-group-switch">
            <el-button class="lc-button" type="primary">OTA营收占比</el-button>
            <el-button class="lc-button" type="primary">RGI</el-button>
            <el-button class="lc-button" type="primary">COCC</el-button>
          </el-button-group>
        </div>
      `,
    };
  },
  methods: {
    copyElement(val) {
      const nodeTextarea = document.createElement('textarea');
      // 隐藏该元素
      nodeTextarea.style.opacity = 0;
      // 写入元素内容（要复制的文本）
      nodeTextarea.value = val;
      // 将元素加入Dom中
      document.body.appendChild(nodeTextarea);
      // 选中该元素
      nodeTextarea.select();
      // 复制以选中元素的内容到粘贴板
      document.execCommand('Copy');
      // 复制完毕，移除该元素
      document.body.removeChild(nodeTextarea);
      this.$message.success('复制成功');
    }

  }

  
}
</script>

<style lang="less" scoped>
.box-main {

    .text-show {
      margin: 40px;
      text-align: left;
    }
  


}
</style>