<template>
  <div class="ui-main-box">
    <div class="box-header">
      <strong>Pagination 分页</strong>
    </div>
    <div class="box-content">
      <el-pagination
        class="lc-pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="100"
        layout="total, sizes, prev, pager, next"
        :total="40000">
      </el-pagination>
      <div class="codeBlock">
        <el-button type="text" icon="el-icon-document-copy" @click="copyElement(paginationElmentData)">复制代码</el-button>
        <div>
          <pre><code>{{ paginationElmentData }}</code></pre>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentPage: 1,
      paginationElmentData: `
        <template>
          <el-pagination
            class="lc-pagination"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, sizes, prev, pager, next"
            :total="40000">
          </el-pagination>
        </template>
        <script>
          export default {
            data() {
              return {
                currentPage: 1
              },
            },
            methods: {
              handleSizeChange(val) {
                console.log('每页数量:', val);
              },
              handleCurrentChange(val) {
                console.log('当前页数:', val);
              },
            }
          }
        <\/script>
      `,
    }
  },
  mounted() {},
  methods: {
    handleSizeChange(val) {
      console.log('每页数量:', val);
    },
    handleCurrentChange(val) {
      console.log('当前页数:', val);
    },
    copyElement(val) {
      const nodeTextarea = document.createElement('textarea');
      // 隐藏该元素
      nodeTextarea.style.opacity = 0;
      // 写入元素内容（要复制的文本）
      nodeTextarea.value = val;
      // 将元素加入Dom中
      document.body.appendChild(nodeTextarea);
      // 选中该元素
      nodeTextarea.select();
      // 复制以选中元素的内容到粘贴板
      document.execCommand('Copy');
      // 复制完毕，移除该元素
      document.body.removeChild(nodeTextarea);
      this.$message.success('复制成功');
    }
  }
}
</script>
<style lang="less" scoped>

</style>