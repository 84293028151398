<template>
  <div class="ui-main-box" style="text-align: left;">
    <div class="box-header">
      <strong>messageVue 消息提示</strong>
    </div>
    <div class="box-content">
      <div class="secondTitle">点击展示对应类型的message</div>
      <el-button :plain="true" @click="open('success')">成功</el-button>
      <el-button :plain="true" @click="open('warning')">警告</el-button>
      <el-button :plain="true" @click="open('info')">消息</el-button>
      <el-button :plain="true" @click="open('error')">错误</el-button>
      <div class="codeBlock">
        <el-button type="text" icon="el-icon-document-copy" @click="copyElement(elmentData)">复制代码</el-button>
        <div>
          <pre><code>{{elmentData}}</code></pre>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      elmentData: `
      this.$message({
        message: '这是一条message',
        type, // success warning info error
        customClass: 'lc-message'
      });
      `,
    }
  },
  methods: {
    copyElement(val) {
      const nodeTextarea = document.createElement('textarea');
      // 隐藏该元素
      nodeTextarea.style.opacity = 0;
      // 写入元素内容（要复制的文本）
      nodeTextarea.value = val;
      // 将元素加入Dom中
      document.body.appendChild(nodeTextarea);
      // 选中该元素
      nodeTextarea.select();
      // 复制以选中元素的内容到粘贴板
      document.execCommand('Copy');
      // 复制完毕，移除该元素
      document.body.removeChild(nodeTextarea);
      this.$message.success('复制成功');
    },
    open(type) {
      this.$message({
        message: '这是一条message',
        type,
        customClass: 'lc-message',
        duration: 10*1000
      });
    }
  }
}
</script>
<style lang="less" scoped>

</style>
