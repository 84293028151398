<template>
  <div class="ui-main-box">
    <div class="box-header">
      <strong>二次确认弹框</strong>
    </div>
    <div class="box-info-text">
      注意：为了避免影响到旧代码的样式，使用时需增加 <p> “customClass: 'lc-message-box'" </p>。以下代码可复制后直接使用。
    </div>
    <div class="box-content">
      <div class="secondTitle">组件示例</div>
      <div style="text-align: left;">
        <el-button class="lc-button" type="primary" @click="openDialog">打开弹窗</el-button>
      </div>
      <div class="codeBlock">
        <el-button type="text" icon="el-icon-document-copy" @click="copyElement(elmentData)">复制代码</el-button>
        <div>
          <pre><code>{{elmentData}}</code></pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      elmentData: `
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          customClass: 'lc-message-box',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      `,
    }
  },
  methods: {
    copyElement(val) {
      const nodeTextarea = document.createElement('textarea');
      // 隐藏该元素
      nodeTextarea.style.opacity = 0;
      // 写入元素内容（要复制的文本）
      nodeTextarea.value = val;
      // 将元素加入Dom中
      document.body.appendChild(nodeTextarea);
      // 选中该元素
      nodeTextarea.select();
      // 复制以选中元素的内容到粘贴板
      document.execCommand('Copy');
      // 复制完毕，移除该元素
      document.body.removeChild(nodeTextarea);
      this.$message.success('复制成功');
    },
    openDialog() {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        customClass: 'lc-message-box',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
}
</script>

<style scoped>
.content-main {
  text-align: left;
  padding: 30px;
}

span {
  font-weight: bold;
  font-size: 14px;
  margin-right: 2px;
}

p {
  line-height: 24px;
}

h4 {
  margin-bottom: 8px;
}

.part {
  margin-bottom: 40px;
}
</style>