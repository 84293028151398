<template>
  <div class="ui-main-box">
    <div class="box-header">
      <strong>Switch 开关</strong>
    </div>
    <div class="box-content">

      <div class="content-title">开关示例</div>
      <div class="content-demo">
        <div class="content-item">
          <span>默认状态</span>
          <el-switch class="lc-switch" v-model="value1"></el-switch>
          <el-switch class="lc-switch" v-model="value2"></el-switch>  
        </div>
        <div class="content-item">
          <span>禁用状态</span>
          <el-switch class="lc-switch" v-model="value3" disabled></el-switch>
          <el-switch class="lc-switch" v-model="value4" disabled></el-switch>  
        </div>
      </div>
      <div class="codeBlock">
        <el-button type="text" icon="el-icon-document-copy" @click="copyElement(setpData1)">复制代码</el-button>
        <div>
          <pre><code>{{ setpData1 }}</code></pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      setpData1: `    
        <el-cascader class="lc-cascader" popper-class="lc-cascader" v-model="value" :options="options1"/>
      `,
      value1: true,
      value2: false,
      value3: true,
      value4: false
    };
  },
  methods: {
    copyElement(val) {
      const nodeTextarea = document.createElement("textarea");
      // 隐藏该元素
      nodeTextarea.style.opacity = 0;
      // 写入元素内容（要复制的文本）
      nodeTextarea.value = val;
      // 将元素加入Dom中
      document.body.appendChild(nodeTextarea);
      // 选中该元素
      nodeTextarea.select();
      // 复制以选中元素的内容到粘贴板
      document.execCommand("Copy");
      // 复制完毕，移除该元素
      document.body.removeChild(nodeTextarea);
      this.$message.success("复制成功");
    },
  },
  created() { },
};
</script>

<style lang="less" scoped>
.box-content {
  .content-title {
    text-align: left;
    padding: 20px 0;
    font-size: 24px;
    color: #000;
  }

  .content-demo {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    flex-direction: column;

    .content-item {
      width: 300px;
      margin-right: 100px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .content-desc {
      // width: 100px;
      text-align: left;
      margin-bottom: 10px;
    }
  }
}
</style>
