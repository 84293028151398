<template>
  <div class="listPageInfoComm ui-main-box">
    <div class="box-header">
      <strong>List 列表页面布局</strong>
    </div>
    <div class="box-info-text">
      注意：列表中的样式为定制版，所以className复制后不可更改。tableHeight为变量，<p>表格高度必须撑满页面高度 </p>，可根据页面实际情况更改预定变量值。
    </div>
    <div ref="searchBlockRef">     
      <template>
        <el-card shadow="never">
          <div class="lc-search-form-collapse">
            <div class="filter-margin">
              <el-input 
                class="lc-input prefix-input prefix-icon-input"
                prefix-icon="el-icon-search" 
                v-model="querySearchData.orderNumber" 
                placeholder="查询订单号"
              />
            </div>
            <div class="filter-margin">
              <el-select
                popper-class="lc-select-popper"
                class="lc-select prefix-text-select four-word-prefix-input"
                filterable 
                clearable
                v-model="querySearchData.orderStatus"
                placeholder="请选择"
              >
                <template slot="prefix">订单状态</template>
                <el-option
                  v-for="item in orderStatusList"
                  :key="item.val"
                  :label="item.name"
                  :value="item.val"
                />
              </el-select>
            </div>
            <div class="filter-margin">
              <el-select
                popper-class="lc-select-popper"
                class="lc-select has-right filterable"
                filterable 
                clearable
                v-model="querySearchData.dateType"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dateTypeList"
                  :key="item.val"
                  :label="item.name"
                  :value="item.val"
                />
              </el-select>
              <el-date-picker
                class="lc-date-picker has-left" 
                popper-class="lc-date-picker" 
                :style="{ width: '262px' }"
                @change="chooseDate"
                v-model="querySearchData.dateList"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="入住日期"
                end-placeholder="离店日期"
              />
            </div>
            <div class="filter-margin">
              <el-select class="lc-select prefixText muiltSelect" v-model="querySearchData.hotelIdList" :class="{'hasData': querySearchData.hotelIdList.length > 0}" multiple collapse-tags filterable clearable placeholder="全部">
                <template slot="prefix">酒店名称/UPID</template>
                <el-option v-for="item in selHotelList" :key="item.id" :value="item.id" :label="item.name" />
              </el-select>
            </div>
            <div class="filter-margin" v-show="showAllConditons">
              <el-input 
                class="lc-input prefix-input four-word-prefix-input"
                v-model="querySearchData.name" 
                placeholder="请输入"
              >
                <template slot="prefix">客人姓名</template>
              </el-input>
            </div>
            <div class="filter-margin" v-show="showAllConditons">
              <el-input 
                class="lc-input prefix-input four-word-prefix-input"
                v-model="querySearchData.phone" 
                placeholder="请输入"
              >
                <template slot="prefix">客人电话</template>
              </el-input>
            </div>
            <div class="filter-margin" v-show="showAllConditons">
              <el-select
                popper-class="lc-select-popper"
                class="lc-select prefix-text-select four-word-prefix-input"
                filterable 
                clearable
                v-model="querySearchData.payType"
                placeholder="请选择"
              >
                <template slot="prefix">支付方式</template>
                <el-option
                  v-for="item in payTypeList"
                  :key="item.val"
                  :label="item.name"
                  :value="item.val"
                />
              </el-select>
            </div>
            <div class="filter-margin" v-show="showAllConditons">
              <el-select
                popper-class="lc-select-popper"
                class="lc-select prefix-text-select two-word-prefix-input"
                filterable 
                clearable
                v-model="querySearchData.channel"
                placeholder="请选择"
              >
                <template slot="prefix">渠道</template>
                <el-option
                  v-for="item in channelList"
                  :key="item.val"
                  :label="item.name"
                  :value="item.val"
                />
              </el-select>
            </div>
            <div
              class="search-btn-container"
              :style="showAllConditons ? 'padding-top:0;margin-bottom:12px' : 'margin-bottom:12px'"
            >
              <el-button
                class="lc-button"
                type="primary"
                @click="queryTableList"
                size="mini"
                v-preventReClick="2000"
              >
                查询
              </el-button>
              <el-button
                class="lc-button"
                @click="resetSearchType"
                size="mini"
                type="info"
              >
                重置
              </el-button>
              <el-link
                :underline="false"
                @click="showAllConditons = !showAllConditons"
                style="margin: 0 8px 0 8px;position:relative"
              >
                  <template v-if="showAllConditons === true">
                    <span class="collapse-btn">收起</span>
                    <i class="el-icon-arrow-up"></i>
                  </template>
                  <template v-else>
                    <span class="expand-btn">展开</span>
                    <i class="el-icon-arrow-down"></i>
                  </template>
              </el-link>
            </div>
          </div>
        </el-card>
      </template>
    </div>
    <div class="tableBlock lc-table">
      <el-table :data="tableData" border :height="tableHeight">
        <el-table-column v-for="item in tableColumn" :key="item.prop" :prop="item.prop" :label="item.label" :width="item.width" :min-width="item.minWidth" show-overflow-tooltip>
          <template slot-scope="scope">
            <template>
              {{ (scope.row[item.prop] || '- -')}}
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        class="lc-pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="100"
        layout="total, sizes, prev, pager, next"
        :total="40000">
      </el-pagination>
    </div>
    <div class="codeBlock">
      <el-button type="text" icon="el-icon-document-copy" @click="copyElement(copyData)">复制代码</el-button>
      <div>
        <pre><code>{{copyData}}</code></pre>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      tableHeight: 450,
      totalCount: 20,
      params: {
        orderId: '',
        orderTagType: ''
      },
      currentPage: 1,
      orderFlgList:[
        {orderflg: 1, orderflgtext: '选项1'},
        {orderflg: 2, orderflgtext: '选项2'},
        {orderflg: 3, orderflgtext: '选项3'},
      ],
      tableData: [
        {
          id: 1,
          componentCategory: '优惠券组件',
          name: '123优惠',
        },
        {
          id: 2,
          componentCategory: '图片组件',
          name: '123图片',
        }
      ],
      tableColumn: [
        {label: '订单号', prop: 'id'},
        {label: '订单名称', prop: 'componentCategory'},
        {label: '备注', prop: 'name'},
      ],
      showAllConditons: false,
      hotelIdList: [
        {val: 1, name: "UPID"},
        {val: 2, name: "携程ID"},
      ],
      selHotelList: [
        {
          "id": 210651,
          "name": "中濠嘉际酒店（东莞松山湖北站店）",
          "upid": 210651
        },
        {
          "id": 222115,
          "name": "中濠悦际酒店（深圳国际会展中心店）",
          "upid": 222115
        },
      ],
      dateTypeList: [
        {val: 1, name: "预定日期"},
        {val: 2, name: "入住日期"},
      ],
      payTypeList: [
        {val: 1, name: "支付宝"},
        {val: 2, name: "微信"},
        {val: 3, name: "现金"},
      ],
      channelList: [
        {val: 1, name: "抖音"},
        {val: 2, name: "携程"},
        {val: 3, name: "去哪儿"},
      ],
      orderStatusList: [
        {val: 1, name: "已成交"},
        {val: 2, name: "待付款"},
        {val: 3, name: "待收货"},
      ],
      querySearchData: {
        orderNumber: '',
        name: undefined,
        phone: undefined,
        dateType: 1,
        orderStatus: undefined,
        hotelIdList: [],
        payType: undefined,
        channel: undefined,
        dateList: [],
        page: {
          pageNum: 1,
          pageSize: 10,
        },
      },
      copyData: `
      <div class="listPageInfoComm">
        <div ref="searchBlockRef">
        //================  搜索条件区域 start================== 
          <template>
            <el-card shadow="never">
              <div class="lc-search-form-collapse">
                <div class="filter-margin">
                  <el-input 
                    class="lc-input prefix-input prefix-icon-input"
                    prefix-icon="el-icon-search" 
                    v-model="querySearchData.orderNumber" 
                    placeholder="查询订单号"
                  />
                </div>
                <div class="filter-margin">
                  <el-select
                    popper-class="lc-select-popper"
                    class="lc-select prefix-text-select four-word-prefix-input"
                    filterable 
                    clearable
                    v-model="querySearchData.orderStatus"
                    placeholder="请选择"
                  >
                    <template slot="prefix">订单状态</template>
                    <el-option
                      v-for="item in orderStatusList"
                      :key="item.val"
                      :label="item.name"
                      :value="item.val"
                    />
                  </el-select>
                </div>
                <div class="filter-margin">
                  <el-select
                    popper-class="lc-select-popper"
                    class="lc-select date-type-lc-select filterable"
                    filterable 
                    clearable
                    v-model="querySearchData.dateType"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in dateTypeList"
                      :key="item.val"
                      :label="item.name"
                      :value="item.val"
                    />
                  </el-select>
                  <el-date-picker
                    class="lc-date-picker" 
                    popper-class="lc-date-picker" 
                    :style="{ width: '262px' }"
                    @change="chooseDate"
                    v-model="querySearchData.dateList"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="入住日期"
                    end-placeholder="离店日期"
                  />
                </div>
                <div class="filter-margin">
                  <el-select class="lc-select prefixText muiltSelect" v-model="querySearchData.hotelIdList" :class="{'hasData': querySearchData.hotelIdList.length > 0}" multiple collapse-tags filterable clearable placeholder="全部">
                    <template slot="prefix">酒店名称/UPID</template>
                    <el-option v-for="item in selHotelList" :key="item.id" :value="item.id" :label="item.name" />
                  </el-select>
                </div>
                <div class="filter-margin" v-show="showAllConditons">
                  <el-input 
                    class="lc-input prefix-input four-word-prefix-input"
                    v-model="querySearchData.name" 
                    placeholder="请输入"
                  >
                    <template slot="prefix">客人姓名</template>
                  </el-input>
                </div>
                <div class="filter-margin" v-show="showAllConditons">
                  <el-input 
                    class="lc-input prefix-input four-word-prefix-input"
                    v-model="querySearchData.phone" 
                    placeholder="请输入"
                  >
                    <template slot="prefix">客人电话</template>
                  </el-input>
                </div>
                <div class="filter-margin" v-show="showAllConditons">
                  <el-select
                    popper-class="lc-select-popper"
                    class="lc-select prefix-text-select four-word-prefix-input"
                    filterable 
                    clearable
                    v-model="querySearchData.payType"
                    placeholder="请选择"
                  >
                    <template slot="prefix">支付方式</template>
                    <el-option
                      v-for="item in payTypeList"
                      :key="item.val"
                      :label="item.name"
                      :value="item.val"
                    />
                  </el-select>
                </div>
                <div class="filter-margin" v-show="showAllConditons">
                  <el-select
                    popper-class="lc-select-popper"
                    class="lc-select prefix-text-select two-word-prefix-input"
                    filterable 
                    clearable
                    v-model="querySearchData.channel"
                    placeholder="请选择"
                  >
                    <template slot="prefix">渠道</template>
                    <el-option
                      v-for="item in channelList"
                      :key="item.val"
                      :label="item.name"
                      :value="item.val"
                    />
                  </el-select>
                </div>
                <div
                  class="search-btn-container"
                  :style="showAllConditons ? 'padding-top:0;margin-bottom:12px' : 'margin-bottom:12px'"
                >
                  <el-button
                    class="lc-button"
                    type="primary"
                    @click="queryTableList"
                    size="mini"
                    v-preventReClick="2000"
                  >
                    查询
                  </el-button>
                  <el-button
                    class="lc-button"
                    @click="resetSearchType"
                    size="mini"
                    type="info"
                    style="margin-left: 4px"
                  >
                    重置
                  </el-button>
                  <el-link
                    :underline="false"
                    @click="showAllConditons = !showAllConditons"
                    style="margin: 0 8px 0 8px;position:relative"
                  >
                    <template v-if="showAllConditons === true">
                      <span style="color:#3E4B6C">收起</span>
                      <svg
                        style="position: relative; top: 3px; color: rgb(62, 75, 108);"
                        t="1635920221708"
                          class="icon"
                          viewBox="0 0 1024 1024"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          p-id="3506"
                          width="16"
                          height="16"
                        >
                          <path
                            d="M253.696 586.368l202.709333-222.378667c27.904-30.336 82.005333-30.336 109.909334 0L770.133333 586.368a31.978667 31.978667 0 1 1-47.104 43.306667L519.552 408.746667a10.858667 10.858667 0 0 0-15.488 0L300.8 629.674667a32 32 0 0 1-47.104-43.306667z"
                            fill="rgb(62, 75, 108)"
                            p-id="3507"
                          />
                      </svg>
                    </template>
                    <template v-else>
                      <span style="color:#3E4B6C">展开</span>
                      <svg
                        style="position: relative; top: 3px;left:-4px;"
                        t="1635920388595"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="4296"
                        width="16"
                        height="16"
                      >
                        <path
                          d="M301.013333 394.24c-12.842667-12.202667-32.128-14.229333-44.970666-2.048-12.842667 12.181333-14.997333 30.464-2.133334 42.666667 141.312 146.24 216.277333 225.472 226.986667 235.626666 14.976 16.234667 44.949333 16.234667 59.946667 0 12.842667-14.229333 87.786667-91.413333 229.12-235.648 12.864-12.16 10.709333-32.490667-2.133334-42.666666-12.842667-10.133333-34.261333-10.133333-44.970666 2.048l-207.722667 215.317333c-2.133333 2.026667-6.4 2.026667-8.554667 0L301.013333 394.24z"
                          fill="rgb(62, 75, 108)"
                          p-id="4297"
                        ></path>
                      </svg>
                    </template>
                  </el-link>
                </div>
              </div>
            </el-card>
          </template>
          //================  搜索条件区域 end==================
        </div>
        <div class="tableBlock lc-table">
          //================  table表格区域 start==================
          <el-table :data="tableData" border :height="tableHeight">
            <el-table-column v-for="item in tableColumn" :key="item.prop" :prop="item.prop" :label="item.label" :width="item.width" :min-width="item.minWidth" show-overflow-tooltip>
              <template slot-scope="scope">
                <template>
                  {{ (scope.row[item.prop] || '- -')}}
                </template>
              </template>
            </el-table-column>
          </el-table>
          //================  table表格区域 end==================
        </div>
        <div class="paginationBlock">
          //================  分页 start==================
          <el-pagination
            class="lc-pagination"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, sizes, prev, pager, next"
            :total="40000">
          </el-pagination>
          //================  分页 end==================
        </div>
      </div>
      <script>
      export default {
        data(){
          return {
            tableHeight: 450,
            currentPage: 1,
          }
        },
        mounted() {
          this.$nextTick(()=>{
            this.tableHeight =  document.body.clientHeight - (this.$refs.searchBlockRef.clientHeight + 178) //178根据页面情况自行调整
          })
        },
         methods: {
          handleSizeChange(val) {
            console.log('每页数量:', val);
          },
          handleCurrentChange(val) {
            console.log('当前页数:', val);
          },
        }
      }
      `,
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.tableHeight =  document.body.clientHeight - (this.$refs.searchBlockRef.clientHeight + 428)
    })
  },
  methods: {
    handleSizeChange(val) {
      console.log('每页数量:', val);
    },
    handleCurrentChange(val) {
      console.log('当前页数:', val);
    },
    copyElement(val) {
      const nodeTextarea = document.createElement('textarea');
      // 隐藏该元素
      nodeTextarea.style.opacity = 0;
      // 写入元素内容（要复制的文本）
      nodeTextarea.value = val;
      // 将元素加入Dom中
      document.body.appendChild(nodeTextarea);
      // 选中该元素
      nodeTextarea.select();
      // 复制以选中元素的内容到粘贴板
      document.execCommand('Copy');
      // 复制完毕，移除该元素
      document.body.removeChild(nodeTextarea);
      this.$message.success('复制成功');
    }
  },
}
</script>