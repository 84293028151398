<template>
  <div class="ui-main-box">
    <div class="box-header">
      <strong>DatePicker 日期选择器</strong>
    </div>
    <div class="box-content">

      <div class="content-title">日期选择器示例</div>
      <div class="content-demo">
        <div class="content-item">
          <div class="content-desc">日期选择器</div>
          <el-date-picker class="lc-date-picker" popper-class="lc-date-picker" v-model="value2" type="date" placeholder="选择日期"></el-date-picker>
         </div>
        <div class="content-item">
          <div class="content-desc">日期范围选择器</div>
          <el-date-picker class="lc-date-picker" popper-class="lc-date-picker" type="daterange" v-model="value1" style="width: 346px;"
            range-separator="至"
            start-placeholder="开始日期" 
            end-placeholder="结束日期">
          </el-date-picker>
         </div>

   
         <div class="content-item">
          <div class="content-desc">月份选择器</div>
          <el-date-picker class="lc-date-picker" popper-class="lc-date-picker" v-model="value5" type="month" placeholder="选择月"></el-date-picker>
         </div>
         <div class="content-item">
          <div class="content-desc">月份范围选择器</div>
          <el-date-picker class="lc-date-picker" popper-class="lc-date-picker" v-model="value4" type="monthrange" style="width: 346px;"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份">
          </el-date-picker>
         </div>
         <div class="content-item">
          <div class="content-desc">周选择器</div>
          <el-date-picker class="lc-date-picker" format="yyyy 第 WW 周" popper-class="lc-date-picker" v-model="value3" type="week" placeholder="选择周"></el-date-picker>
         </div>
         <div class="content-item">
          <div class="content-desc">年份选择器</div>
          <el-date-picker class="lc-date-picker" popper-class="lc-date-picker" v-model="value6" type="year" placeholder="选择年"></el-date-picker>
         </div>
      </div>

      <div class="codeBlock">
        <el-button type="text" icon="el-icon-document-copy" @click="copyElement(setpData1)">复制代码</el-button>
        <div>
          <pre><code>{{ setpData1 }}</code></pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      setpData1: `    
      <div class="content-item">
          <div class="content-desc">日期选择器</div>
          <el-date-picker class="lc-date-picker" popper-class="lc-date-picker" v-model="value2" type="date" placeholder="选择日期"></el-date-picker>
         </div>
        <div class="content-item">
          <div class="content-desc">日期范围选择器</div>
          <el-date-picker class="lc-date-picker" popper-class="lc-date-picker" type="daterange" v-model="value1" style="width: 346px;"
            range-separator="至"
            start-placeholder="开始日期" 
            end-placeholder="结束日期">
          </el-date-picker>
         </div>

   
         <div class="content-item">
          <div class="content-desc">月份选择器</div>
          <el-date-picker class="lc-date-picker" popper-class="lc-date-picker" v-model="value5" type="month" placeholder="选择月"></el-date-picker>
         </div>
         <div class="content-item">
          <div class="content-desc">月份范围选择器</div>
          <el-date-picker class="lc-date-picker" popper-class="lc-date-picker" v-model="value4" type="monthrange" style="width: 346px;"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份">
          </el-date-picker>
         </div>
         <div class="content-item">
          <div class="content-desc">周选择器</div>
          <el-date-picker class="lc-date-picker" popper-class="lc-date-picker" v-model="value3" type="week" placeholder="选择周"></el-date-picker>
         </div>
         <div class="content-item">
          <div class="content-desc">年份选择器</div>
          <el-date-picker class="lc-date-picker" popper-class="lc-date-picker" v-model="value6" type="year" placeholder="选择年"></el-date-picker>
         </div>
      </div>
      `,
      value1: '',
      value2: '',
      value3: '',
      value4: '',
      value5: '',
      value6: '',
    
    };
  },
  methods: {
    copyElement(val) {
      const nodeTextarea = document.createElement("textarea");
      // 隐藏该元素
      nodeTextarea.style.opacity = 0;
      // 写入元素内容（要复制的文本）
      nodeTextarea.value = val;
      // 将元素加入Dom中
      document.body.appendChild(nodeTextarea);
      // 选中该元素
      nodeTextarea.select();
      // 复制以选中元素的内容到粘贴板
      document.execCommand("Copy");
      // 复制完毕，移除该元素
      document.body.removeChild(nodeTextarea);
      this.$message.success("复制成功");
    },
  },
  created() { },
};
</script>

<style lang="less" scoped>
.box-content {
  .content-title {
    text-align: left;
    padding: 20px 0;
    font-size: 24px;
    color: #000;
  }

  .content-demo {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .content-item {
      // width: 300px;
      margin-right: 100px;
      margin-bottom: 20px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }

    .content-desc {
      // width: 100px;
      text-align: left;
      margin-bottom: 10px;
    }
  }
}
</style>
